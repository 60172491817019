import React from "react";
import { ReportByIdAsset, RuleRootWidget, SummaryWidget } from "../../sub";
import { usePostureStateContext } from "../../../../store/PostureStateContextProvider";
import { PostureChartRuleRootSkeleton, PostureChartsSkeleton } from "./PostureChartsSkeleton";

const CHART_MAP = {
    IDENTITY: {
        IdAsset: {
            title: 'IDENTITIES',
            subTitle: 'IDs',
            totalCount: 'identity_count',
            issueCount: 'identity_count_with_issues'
        },
        RuleWidget: {
            title: 'IDENTITY ISSUES',
            chartTitle: 'Identities',
            totalCount: 'identity_rules_summary',
            issueCount: 'identity_count_with_issues'
        }
    },
    ASSET: {
        IdAsset: {
            title: 'ASSETS',
            subTitle: 'Assets',
            totalCount: 'asset_count',
            issueCount: 'asset_count_with_issues'
        },
        RuleWidget: {
            title: 'ASSET ISSUES',
            chartTitle: 'Assets',
            totalCount: 'asset_rules_summary',
            issueCount: 'asset_count_with_issues'
        }
    },
    ACCESS: {
        IdAsset: {
            title: 'ACCESSES',
            subTitle: 'Accesses',
            totalCount: 'access_count',
            issueCount: 'access_count_with_issues'
        },
        RuleWidget: {
            title: 'ACCESS PROTOCOLS',
            chartTitle: 'Access',
            totalCount: 'access_protocol_summary',
            issueCount: 'access_count_with_issues'
        }
    },
    DIRECTORY: {
        IdAsset: {
            title: 'IDENTITY SYSTEMS',
            subTitle: 'Directories',
            totalCount: 'directory_count',
            issueCount: 'directory_count_with_issues'
        },
        RuleWidget: {
            title: 'IDENTITY SYSTEMS ISSUES',
            chartTitle: 'Directories',
            totalCount: 'directory_rules_summary',
            issueCount: 'directory_count_with_issues'
        }
    }
}


export const PostureCharts = ({ entityType }) => {
    const checkFlag = false;
    const { PostureChartState } = usePostureStateContext();
    const { widgetData, showAdvanceSearch,
        idWidget, ruleWidget, ruleRootIdentity, showGraph, responseData, selectedItems, items } = PostureChartState;
    const ruleWidgetTotal = CHART_MAP[entityType].RuleWidget.totalCount
    const ruleWidgetIssue = CHART_MAP[entityType].RuleWidget.issueCount
    return <>
        {widgetData && responseData ?
            <div className="idasset_widget_summary_box"
                style={{
                    pointerEvents: showAdvanceSearch ? 'none' : 'initial'
                }}
            >
                {(idWidget !== undefined &&
                    ruleWidget !== undefined &&
                    ruleRootIdentity !== undefined) ?
                    <>
                        <SummaryWidget issues={widgetData} />
                        <ReportByIdAsset customData={{
                            title: CHART_MAP[entityType].IdAsset.title,
                            subtitle: CHART_MAP[entityType].IdAsset.subTitle,
                            is_exclude: checkFlag,
                            totalCount: widgetData?.[CHART_MAP[entityType].IdAsset.totalCount],
                            issueCount: widgetData?.[CHART_MAP[entityType].IdAsset.issueCount]
                        }} issues={idWidget} />
                        {showGraph ? <RuleRootWidget customData={{
                            title: CHART_MAP[entityType].RuleWidget.title,
                            chartTitle: CHART_MAP[entityType].RuleWidget.chartTitle,
                            is_exclude: checkFlag,
                            totalCount: widgetData?.[ruleWidgetTotal]?.rules_count ||
                                widgetData?.[ruleWidgetTotal]?.count,
                            issueCount: widgetData?.[ruleWidgetIssue]
                        }} issues={ruleWidget} selectedData={selectedItems} rootDetail={ruleRootIdentity} groupData={items} />
                            : <div className="posture-chart-skeleton-container">
                                <PostureChartRuleRootSkeleton />
                            </div>
                        }
                        {/* <ReportByIdAsset customData={{title:"ASSETS", 
                is_exclude : checkFlag,
                totalCount:widgetData?.asset_count_internal, issueCount:widgetData?.asset_count_with_issues_internal}} issues={assetWidget}/> */}
                    </>
                    : <PostureChartsSkeleton />}
            </div>
            : (!responseData || !widgetData ? <PostureChartsSkeleton /> :
                <PostureChartsSkeleton />)
        }</>
}