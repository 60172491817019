import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useWatch } from 'react-hook-form';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Tooltip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoIcon from '@mui/icons-material/Info';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { Api } from '../../../components/Axios';
import { isValidIp } from '../../../utils/util-methods';
import { AVAILABLE_SIEMS, MESSAGES, SENSOR_SETTINGS_KEYS, SensorLabels } from '../constants/sensor-labels';
import './flowSensorConfiguration.scss';
import { AlertComponent } from '../AlertComponent';
import { formatDate } from '../../../utils/util-methods';
import CheckboxAutocomplete from '../../../components/core/AMAutoComplete/AMAutoComplete';

export const FlowSensorConfiguration = ({
    register,
    setError,
    clearErrors,
    setValue,
    getValues,
    flowSensorData,
    isHostedFlowSensor = false,
    isEdit
}) => {
    const [dns_servers, setDnsServers] = useState([]);
    const [dnsSearchKeyErrorMessage, setDnsSearchKeyErrorMessage] =
        useState('');
    const [dns_search_domain_keys, setDnsDomainSearchKey] = useState([]);
    const [isGCQuery, setIsGCQuery] = useState(false);
    const [proxyType, setProxyType] = useState([]);
    const [noProxy, setNoProxy] = useState(false);
    const [enrichmentSensor, setEnrichmentSensor] = useState([]);
    const [availableProxies, setAvailableProxies] = useState([]);
    const [availableEnrichmentSensors, setAvailableEnrichmentSensors] =
        useState([]);
    const [alerts, setAlerts] = useState([]);

    const AddTags = (tag: any) => {
        for (let index = 0; index < tag.length; index++) {
            if (!isValidIp(tag[index])) {
                setError('dns_servers', { type: 'invalidIp' });
                return;
            } else {
                clearErrors('dns_servers');
            }
        }
        setDnsServers(tag);
        setValue('dns_servers', tag);
    };

    const getProxies = () => {
        Api.get('/proxies')
            .then((res: { data: any }) => {
                if (res.data && res.data.result.length > 0) {
                    setAvailableProxies(res.data.result);
                    setNoProxy(false);
                    if (flowSensorData) {
                        const match = res.data.result.find(
                            (item) =>
                                item._id == flowSensorData['proxy_id']
                        );
                        if(match){
                            
                        setProxyType([{ key: match?._id, value: match?.name }]);
                        setValue("proxy_id", [{ key: match?._id, value: match?.name }]);

                        }
                    }
                    
                } else {
                    setNoProxy(true);
                }
                fetchEnrichmectSensor();
            })
            .catch(() => {});
    };

    const fetchEnrichmectSensor = () => {
        Api.get('/sensors/configuredenrichmentsensors')
            .then((res) => {
                if (res.status === 200) {
                    if(res.data.result){
                    setAvailableEnrichmentSensors(res.data.result);
                    if (flowSensorData) {
                        const match = res.data.result.find(
                            (item) =>
                                item._id ==
                                flowSensorData['enrichment_sensor_id']
                        );
                        setEnrichmentSensor([
                            { key: match._id, value: match.name }
                        ]);
                        setValue("enrichment_sensor_id", [{ key: match._id, value: match.name }]);
                    }
                  } else {
                    setAvailableEnrichmentSensors([])
                  }
                }
            })
            .catch(() => {});
    };

    useEffect(() => {
        if (!isEdit)
            getProxies();
    }, []);

    useEffect(() => {
        if (flowSensorData) {
            getProxies()
            setDnsServers(flowSensorData['dns_servers']);
            setDnsDomainSearchKey(flowSensorData['dns_search_domain_keys']);
            setIsGCQuery(flowSensorData['query_gc']);
            setAlerts(getAlertMessages(flowSensorData));
        }
    }, [flowSensorData]);

    useEffect(() => {
        if (flowSensorData && availableEnrichmentSensors.length > 0) {
            setValue(
                'enrichment_sensor_id',
                flowSensorData.enrichment_sensor_id
            );
        }
    }, [flowSensorData, availableEnrichmentSensors, setValue]);

    const handleDnsSearchKeyInputChange = (event: any) => {
        const value = event.target.value;
        // Check the number of comma-separated strings
        const domain_search_keys = value
            .split(',')
            .map((entry) => entry.trim());
        if (domain_search_keys.length > 6) {
            setDnsSearchKeyErrorMessage(
                'Please enter up to 6 comma-separated domain search keys.'
            );
        } else if (
            new Set(domain_search_keys).size !== domain_search_keys.length
        ) {
            setDnsSearchKeyErrorMessage('Duplicate strings are not allowed.');
        } else if (domain_search_keys.slice(1).some((entry) => entry === '')) {
            setDnsSearchKeyErrorMessage(
                'No empty domain string is allowed after comma'
            );
        } else {
            setDnsSearchKeyErrorMessage('');
        }
        setDnsDomainSearchKey(
            Array.from(
                new Set(domain_search_keys.filter((str) => str.trim() !== ''))
            )
        );
    };

    const registerFields = () => {
        register('dns_servers', {
            required: true,
            shouldUnregister: true
        });
        register('dns_search_domain_keys', {
            shouldUnregister: true
        });
        register('proxy_id', {
            required: false,
            shouldUnregister: true
        });
        register('enrichment_sensor_id', {
            required: false,
            shouldUnregister: true
        });
    };

    useEffect(() => {
        registerFields();
    }, []);

    const getAlertMessages = (data) => {
        let res = [];
        if (
            moment
                .duration(moment().diff(moment(data.last_flow_time)))
                .asHours() < 2
        ) {
            res.push(
                createAlertMessage(
                    `${MESSAGES[0]} ${formatDate(data.last_flow_time)}`,
                    'success',
                    false
                )
            );
        } else {
            res.push(
                createAlertMessage(
                    data.last_flow_time && moment(data.last_flow_time).isAfter("2021-12-31T01:14:00Z") 
                        ? `${MESSAGES[1]} ${formatDate(data.last_flow_time)} Expand to view specific issue details.`
                        : `${MESSAGES[11]} No flows received yet. Expand to view specific issue details.`,
                    'error',
                    true
                )
            )
            if (data.flows > 0) {
                res.push(
                    createAlertMessage(`${MESSAGES[2]}`, 'success', false)
                );
            } else { 
                // res.push(createAlertMessage(`${MESSAGES[3]}`, 'error', false));

            }
            res.push(
                createAlertMessage(
                    data.last_flow_time && moment(data.last_flow_time).isAfter("2021-12-31T01:14:00Z") 
                    ? `${MESSAGES[4]} ${formatDate(data.last_flow_time)}`
                    : `${MESSAGES[12]} No flows received yet.`,
                    'error',
                    false
                )
            );

            const additionalErrors = sensorSettingsErrors(data);
            if (additionalErrors.length > 0) { 
                additionalErrors.forEach(item => { 
                    res.push(
                        createAlertMessage(
                            `${item}`,
                            'error',
                            false
                        )
                    );
                })
            }
        }

        return res;
    };

    const createAlertMessage = (text, type, isSummary) => {
        return { type, isSummary, text };
    };

    const sensorSettingsErrors = (data) => {
        let res = [];
        if (data.sensor_settings) {
            res = Object.keys(data.sensor_settings).filter(item => SENSOR_SETTINGS_KEYS.includes(item)).map((sensor_setting: any, idx: number) =>
                `${sensor_setting} - ${data.sensor_settings[sensor_setting]}`
            )
        }
        return res;
    }

    const getItemLabel = (
        option:
            | { key: string; value: string }
            | { key: string; value: string }[]
    ) => {
        if (Array.isArray(option)) {
            return option[0]?.value ? option[0]?.value : '';
        } else {
            return option?.value ? option?.value : '';
        }
    };

    useEffect(() => {
        register('proxy_id', {
            required: false,
            shouldUnregister: true
        });
    }, []);

    return (
        <>
         
            <div className='form-body-div scrollbar-container add-sensor-body-div'>
               {alerts.length > 0 && <AlertComponent messages={alerts} />}
                <div className='sensor-flex-container'>
                    <div className='sensor-flex-container-item'>
                        <label>Name*</label>
                        <input
                            type='text'
                            placeholder='Enter Sensor name'
                            name='name'
                            ref={register({
                                required: true,
                                pattern: /^[^'"]*$/,
                                shouldUnregister: true
                            })}
                            className={'flex-basis-50'}
                            autoComplete='off'
                        />
                    </div>

                    <div className='sensor-flex-container-item'>
                        <label>Site Code*</label>
                        <input
                            type='text'
                            placeholder='ENTER 4 DIGIT ALPHANUMERIC CODE'
                            name='location'
                            className='uppercase flex-basis-50'
                            maxLength={4}
                            ref={register({
                                required: true,
                                shouldUnregister: true
                            })}
                        />
                    </div>
                </div>

                <div className='sensor-flex-container'>
                    <div className='font12 sensor-flex-container-item'>
                        <label className='paddingright20'>Proxy</label>
                        {noProxy ? (
                            <>
                                <select
                                    disabled
                                    className=' form_drodown flex-basis-50'
                                >
                                    <option value={'no-proxy'}>
                                        No proxy found
                                    </option>
                                </select>
                                <div className='flex-basis-50'>
                                    <Link
                                        to='/proxy'
                                        className='link-text font12'
                                    >
                                        Add Proxy
                                    </Link>
                                </div>
                            </>
                        ) : (
                            <div
                                style={{ width: '98.5%', marginTop: '12%' }}
                                className='autocom'
                            >
                                <CheckboxAutocomplete
                                    label='Select Proxy'
                                    className={'flex-basis-50 form_drodown'}
                                    options={availableProxies.map((item) => ({
                                        key: item._id,
                                        value: item.name
                                    }))}
                                    value={proxyType}
                                    onChange={(e, val) => {
                                        setProxyType(val);
                                        setValue("proxy_id", val)
                                    }}
                                    getItemLabel={getItemLabel}
                                    multiple={false}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {isEdit && (
                    <div className='sensor-flex-container' style={{marginTop: '10px', marginBottom: '24px'}}>
                         <div className='sensor-flex-container-item'>
                            <label>Assigned SIEM</label>
                            <span className="sub-text">{ flowSensorData?.siem_configured?.name ? flowSensorData?.siem_configured?.name : 'None' }</span>
                            </div>
                    </div>
                )}

                <div className={`form_sub_section`}>
                    <div className='form_sub_section_header'>
                        DNS Server Configuration
                    </div>
                    <div className='form_sub_section_content'>
                        <div className='sensor-flex-container'>
                            <div className='sensor-flex-container-item'>
                                <label htmlFor='searchDomain'>
                                    Search Domains (max 6)
                                </label>
                                <input
                                    type='text'
                                    id='searchDomain'
                                    placeholder='Enter upto 6 comma separated domains to be searched'
                                    name='dns_search_domain_keys'
                                    onChange={handleDnsSearchKeyInputChange}
                                    // required
                                    ref={register({
                                        shouldUnregister: true
                                    })}
                                    className={'flex-basis-70'}
                                />
                                {dnsSearchKeyErrorMessage && (
                                    <div style={{ color: 'red' }}>
                                        {dnsSearchKeyErrorMessage}
                                    </div>
                                )}
                            </div>
                        </div>
                        <>
                            <label style={{ width: '100%' }}>
                                IP Address*{' '}
                                <span className='sub-text'>
                                    (It is recommended to have Private DNS as
                                    first IP address in the list.)
                                </span>
                            </label>

                            <TagsInput
                                value={dns_servers}
                                onChange={AddTags}
                                inputProps={{
                                    placeholder: 'Enter IP Addresses'
                                }}
                            />
                            <div className='warning-container'>
                                <InfoIcon
                                    style={{
                                        fontSize: '1.5em',
                                        color: '#87929E',
                                        fill: 'none',
                                        stroke: '#87929E',
                                        marginBottom: '0.2em'
                                    }}
                                />
                                <span className='sub-text'>
                                    Note: Hit 'Enter' or 'Tab' key after each
                                    DNS server IP.
                                </span>
                            </div>
                        </>
                    </div>
                </div>

                <div className='advanced-options-container'>
                    <Accordion disableGutters elevation={0}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='advanced-options-content'
                            id='advanced-options-header'
                            style={{
                                fontSize: '16px',
                                fontWeight: '500',
                                padding: '0px',
                                backgroundColor: '#F8F8F8'
                            }}
                            sx={{
                                '& .MuiAccordionSummary-expandIconWrapper': {
                                    position: 'absolute',
                                    fontSize: '18px'
                                },
                                '& .MuiSvgIcon-fontSizeMedium': {
                                    fontSize: '18px',
                                    fontWeight: 'bold',
                                    color: 'black'
                                }
                            }}
                        >
                            <span className='collapsible-item-header'>
                                Advanced Options
                            </span>
                        </AccordionSummary>
                        <AccordionDetails
                            style={{
                                padding: '0px',
                                backgroundColor: '#F8F8F8'
                            }}
                        >
                            {/* <div className='sensor-flex-container'>
                                <div className='sensor-flex-container-item'>
                                    <label className='paddingright20'>
                                        SIEM Vendor
                                    </label>
                                    <select
                                        name='siem'
                                        className={
                                            'flex-basis-50 form_drodown'
                                        }
                                        ref={register({
                                            required: false,
                                            shouldUnregister: true
                                        })}
                                        onChange={(e) => {
                                            setSiem(e.currentTarget.value);
                                        }}
                                    >
                                        <option value={''}>
                                            Select SIEM Vendor
                                        </option>
                                        {availableSiemVendors &&
                                            availableSiemVendors.map(
                                                (u: any) => (
                                                    <option
                                                        key={u.value}
                                                        value={u.value}
                                                        selected={
                                                            siem == u.value
                                                        }
                                                    >
                                                        {u.label}
                                                    </option>
                                                )
                                            )}
                                    </select>
                                </div>
                            </div> */}
                            <div className='sensor-flex-container'>
                                <div className='sensor-flex-container-item'>
                                    <label className='margintop10'>
                                        Override IP{' '}
                                    </label>

                                    <input
                                        type='text'
                                        placeholder='Enter IP address'
                                        name='override_ip'
                                        className='flex-basis-50'
                                        ref={register({
                                            required: false,
                                            shouldUnregister: true
                                        })}
                                    />
                                </div>
                            </div>
                            <div className='sensor-flex-container'>
                                <div className='sensor-flex-container-item'>
                                    <label>Enrichment Sensor</label>
                                    <div
                                        style={{
                                            width: '98.5%',
                                            marginTop: '12%'
                                        }}
                                        className='autocom'
                                    >
                                        <CheckboxAutocomplete
                                            label='Select Enrichment Sensor'
                                            className={
                                                'flex-basis-50 form_drodown'
                                            }
                                            options={availableEnrichmentSensors?.map(
                                                (item) => ({
                                                    key: item._id,
                                                    value: item.name
                                                })
                                            )}
                                            value={enrichmentSensor}
                                            onChange={(e, val) => {
                                                setEnrichmentSensor(val);
                                                setValue("enrichment_sensor_id", val)
                                            }}
                                            getItemLabel={getItemLabel}
                                            multiple={false}
                                        />
                                    </div>
                                    <div className='clrBoth'></div>
                                </div>
                            </div>

                            <input
                                name='query_gc'
                                type='checkbox'
                                className={'margintop20'}
                                checked={isGCQuery}
                                onChange={() => setIsGCQuery(!isGCQuery)}
                                ref={register({
                                    shouldUnregister: true
                                })}
                            />
                            <label
                                style={{
                                    float: 'unset'
                                }}
                                className='auto_width paddingright20'
                            >
                                Do not query the Global Catalog or LDAP Server
                            </label>
                            <div className='warning-container'>
                                <InfoIcon
                                    style={{
                                        fontSize: '1.5em',
                                        color: '#87929E',
                                        fill: 'none',
                                        stroke: '#87929E',
                                        marginBottom: '0.2em'
                                    }}
                                />
                                <span className='sub-text'>
                                    Note: Directory query parameters are
                                    required for proper functioning of Flow
                                    Sensor.&nbsp;{' '}
                                    <Link
                                        className='link-text font12'
                                        to='/dirQueryParam'
                                    >
                                        <span className='font12'>
                                            You can set them here.
                                        </span>
                                    </Link>
                                </span>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </div>
        </>
    );
};
