import moment, { Moment } from "moment";
import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import Popup from "reactjs-popup";
import FlowChart from "../../../components/Widgets/flows-chart/FlowChart";
import { IssueFlow } from "../../../types/response-types";
import { addDaysToEpoc, convertToCommaValue, getTimeDiffString } from "../../../utils/util-methods";
import "./flow-trend.css";
import DayFlowsChart from "../../../components/Widgets/flows-chart/DayFlowsChart";
import { useTimeBasedSearchActions } from "../../../components/TimeBasedSearch/TimeBasedSearchActions";
import { useHistory, useLocation } from "react-router-dom";

type Props = {
  data: any | undefined;
  closeFlowPopup?: any;
  chartDetails: any;
  onRangeChange?: any;
  zoomLevel?:any;
  updatedExtremes?:any
  showZoom?:boolean;
};

const RiskColor = new Map([['critical', '#fa1262'], ['high', '#ff7101'], ['medium', '#fdbf06'], ['low', '#9fa1ae']]);

const FlowTrend = ({ data = undefined, closeFlowPopup, chartDetails, onRangeChange , zoomLevel ,updatedExtremes  , showZoom}: Props) => {

  const { chartTitle, risk } = chartDetails || { chartTitle: <></>, risk: 'low' };
  const [flowsChartData, setFlowsChartData] = useState([]);
  const [selectedRange, setSelectedRange] = useState('all');
  const [userSelectedRange, setUserSelectedRange] = useState(false);

  const [isNextRangeExceeded, setIsNextRangeExceeded] = useState(true);
  const [isPreviousRangeExceeded, setIsPreviousRangeExceeded] = useState(false);

  const [selectedWeekStartDate, setSelectedWeekStartDate] = useState<number | null>(null);
  const { appliedFilterTimestamp } = useTimeBasedSearchActions();
  const params = useLocation();
  const history = useHistory();
  // const updatedExtremesRef = useRef({ min: null, max: null });
  const [startDate, setStartDate] = useState(moment().subtract(60,'days').valueOf());

  const handleClose = () => {
    closeFlowPopup();
  };

  const nextWeekClickHandler = () => {
    try {
      const nextWeekStart = moment(selectedWeekStartDate)?.add(7, 'days').valueOf();
      const nextToNextWeekStart = moment(selectedWeekStartDate)?.add(14, 'days').valueOf();
      setIsPreviousRangeExceeded(false);
      if (moment(nextToNextWeekStart).isAfter(moment())) {
        setIsNextRangeExceeded(true);
      }
      else {
        setIsNextRangeExceeded(false);
      }
      setSelectedWeekStartDate(nextWeekStart);
    }
    catch (err) {
      console.log(err);
    }

  }

  const previousWeekClickHandler = () => {
    try {
      const prevWeekStart = moment(selectedWeekStartDate)?.subtract(7, 'days').valueOf();
      const prevToPrevWeekStart = moment(selectedWeekStartDate)?.subtract(14, 'days').valueOf();
      const st = moment(flowsChartData[0][0]).weekday(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      setIsNextRangeExceeded(false);
      if (moment(prevToPrevWeekStart).isBefore(st)) {
        setIsPreviousRangeExceeded(true);
      }
      else {
        setIsPreviousRangeExceeded(false);
      }
      setSelectedWeekStartDate(prevWeekStart);

    }
    catch (err) {
      console.log(err);
    }
  }

  const handleRangeChange = (min: any, max: any) => {
   
   // updatedExtremesRef.current =({ min: min, max: max });
    onRangeChange(min, max);
      
    
  }


  const renderFlowChart = () => {
    const flowChart = <FlowChart flowsData={flowsChartData} onRangeChange={handleRangeChange} selectedRange={selectedRange} primaryColor={RiskColor.get(risk)} data={chartDetails && chartDetails.itemData ? chartDetails.itemData : null } 
     startDate={startDate} closePopup={closeFlowPopup} updatedExtremes={updatedExtremes} zoomLevel={zoomLevel} showZoom={showZoom}/>;
    // flowsChartData.forEach(element => {
    //   console.log(moment(element[0]).format());
    // });
    if (selectedRange === 'weekly') {
      const weekData = new Map();

      let startOfWeek = moment(selectedWeekStartDate);
      const endOfWeek = moment(selectedWeekStartDate).weekday(7).set({ hour: 23, minute: 59, second: 59 });

      const flowsInWeek = flowsChartData.filter(p => moment(p[0]).isSameOrAfter(startOfWeek) && moment(p[0]).isSameOrBefore(endOfWeek))


      // not required now to set max Y... <DayFlowsChart maxY={maxFlowsinWeek} ... 
      // const maxFlowsinWeek = Math.max(...flowsInWeek.map(p => p[1]));

      console.log('categorize week data....');
      console.log(`last data available on ${moment(flowsChartData[flowsChartData.length - 1][0]).format('MMM DD YYYY, hh:mm A')}`)
      while (startOfWeek.isSameOrBefore(endOfWeek)) {

        const currentDayHourlyData = flowsChartData.filter(p => startOfWeek.isSame(p[0], 'date'));

        currentDayHourlyData.forEach(p => {
          // if(moment(p[0]).isSameOrAfter(startOfWeek) && moment(p[0]).isSameOrBefore(endOfWeek)) {
          const hourlyData = weekData.get(startOfWeek.valueOf());
          if (hourlyData) {
            hourlyData.push(p);
          }
          else {
            weekData.set(startOfWeek.valueOf(), [p]);
          }

          // }
        });


        //stuff null on unavailable data for some hours in a day.
        const hourlyData = weekData.get(startOfWeek.valueOf());
        while (hourlyData) {
          const max = Math.max(...hourlyData.map(p => p[0]));
          if (moment(max).add(1, 'hour').isSame(startOfWeek, 'date')) {
            hourlyData.push([moment(max).add(1, 'hour').valueOf(), null]);
          }
          else {
            break;
          }
        }
        while (hourlyData) {
          const min = Math.min(...hourlyData.map(p => p[0]));
          if (moment(min).subtract(1, 'hour').isSame(startOfWeek, 'date')) {
            hourlyData.splice(0, 0, [moment(min).subtract(1, 'hour').valueOf(), null]);
          }
          else {
            break;
          }
        }

        if (currentDayHourlyData.length === 0) {
          weekData.set(startOfWeek.valueOf(), []);
        }
        startOfWeek.add(1, 'day');
      }

      const weekReverseList = Array.from(weekData.keys());
      let day = -1;
      for (let i = weekReverseList.length; i >= 0; i--) {
        if (weekData.get(weekReverseList[i]) && weekData.get(weekReverseList[i])?.length > 0) {
          day = weekReverseList[i];
          break;
        }
      }

      const flowCharts = Array.from(weekData.keys()).map((key) => {
        if (!weekData.get(key) || weekData.get(key)?.length === 0) {
          return (moment(key).isAfter(moment()))
            ? <DayFlowsChart noDataMessage='No data available' test={key} showNavigator={false} flowsData={weekData.get(key)} primaryColor={RiskColor.get(risk)} zoomLevel={zoomLevel} />
            : <DayFlowsChart noDataMessage='No data available' test={key} showNavigator={false} flowsData={weekData.get(key)} primaryColor={RiskColor.get(risk)} zoomLevel={zoomLevel}  />
        }

        return <DayFlowsChart showXAxisLabels={(day === key)} test={key} showNavigator={false} flowsData={weekData.get(key)} primaryColor={RiskColor.get(risk)}  zoomLevel={zoomLevel} />
      })
      const keys = Array.from(weekData.keys());
      //return <DayFlowsChart test={keys[5]} showNavigator={false} flowsData={weekData.get(keys[5])} primaryColor={RiskColor.get(risk)} />;
      return <>
        {flowCharts}
        {chartDetails && chartDetails.itemData && (<>
          <div style={{display:'flex', justifyContent:'center', alignItems:'center'}} >
            <button
              className='chart-action-btn inline-navigator-chart-btn marginbottom10'
              onClick={onView}
            >
              View Accesses
            </button>
          </div>
        </>)}
      </>;
    }
    return flowChart;
  }

  const getQueryParam = () => {
    const paramsArr = params.search.split("&");
    const qParam = paramsArr.filter(item => item.indexOf("any_activity_time") > -1);
    if (qParam && qParam.length > 0) {
      let decodedStr = qParam[0].split("=");
      let firstDecode = decodeURIComponent(decodedStr[1]);
      let secondDecodeArr = firstDecode.split("+");
      let foundItem = secondDecodeArr.filter(item => item.indexOf("any_activity_time") > -1);
      return foundItem && foundItem[0] ? foundItem[0] : "";
    } else {
      return '';
    }
  }

  const onView = () => {
    if (chartDetails && chartDetails.itemData && chartDetails.itemData.pageType && chartDetails.itemData.pageType === 'issue') {
      history.push(`accesses?order_by=desc&sort_by=score&q=${issuePageType()}`)
    } else {
      history.push(`accesses?order_by=desc&sort_by=score&q=${posturePageType()}`)
    }
  }

  const posturePageType = () => {
    let res = `${getQueryParam()}`
    res = res?.length > 0 ? (res + '+') : ''
    res += chartDetails?.itemData?.queryData

    return `${encodeURIComponent(res)}`;
  }

  const issuePageType = () => {
    let res = `${getQueryParam()}`
    res = res?.length > 0 ? (res + '+') : ''
    res += `identity_name:${chartDetails.itemData['source_name']}+identity_type:${chartDetails.itemData['source_type']}+asset_name:${chartDetails.itemData['destination_name']}+asset_type:${chartDetails.itemData['destination_type']}`
    if (chartDetails.itemData['directory_name'] && chartDetails.itemData['directory_name'] !== "") {
      res += `+dir_name:${chartDetails.itemData['directory_name']}`
    }

    return `${encodeURIComponent(res)}`;
  }

  useEffect(() => {

    let timeFilter = appliedFilterTimestamp();

  

    if (data?.flows && data?.flows?.length > 0) {
      // Use timeFilter values if they are present; otherwise, fall back to original logic
      let timeFilter = appliedFilterTimestamp();
      let startFromDate = updatedExtremes?.current?.min 
        ? moment(updatedExtremes?.current?.min).valueOf()
        : timeFilter?.[0] 
          ? moment(timeFilter[0]).valueOf()
          : Math.max(
            moment(data?.flows[0]?.time).valueOf(),
            moment().subtract(60, 'days').valueOf()
          );

          if(zoomLevel === 'hourly'){
            setStartDate(data?.flows[0]?.time)
          }

      let endDate = updatedExtremes?.current?.max 
        ? moment(updatedExtremes?.current?.max).valueOf()
        : timeFilter?.[1] 
          ? moment(timeFilter[1]).valueOf()
          : moment().valueOf();

      const totalDurationHours = moment(endDate).diff(
        moment(startFromDate),
        'hours'
      );

      // Determine the interval based on the total duration
      let interval, intervalUnit;
      if (totalDurationHours < 12) {
        interval = 1;
        intervalUnit = 'minute';
      } else if (totalDurationHours < 48) {
        interval = 10;
        intervalUnit = 'minute';
      } else {
        interval = 1;
        intervalUnit = 'hour';
      }

      if(updatedExtremes?.current?.min === null  &&  timeFilter.length === 0){
        interval = 1;
        intervalUnit = 'hour';
      }

      // Find the first available flow time
      const firstFlowTime = moment(data.flows[0].time);

      let startDate;

      // Adjust startDate to keep date and hour from startFromDate but copy minutes from firstFlowTime
      if (intervalUnit !== 'minute') {
        startDate = moment(startFromDate)
          .set({
            minute: firstFlowTime.minute(),
            second: 0,
            millisecond: 0
          })
          .startOf(intervalUnit);
      } else {

        startDate = moment(startFromDate);
        const startMinute = startDate.minute();

        // Calculate the buffer based on the interval
        const buffer = interval - (startMinute % interval);
        startDate.add(buffer, 'minutes').startOf('minute');
      }

      const chartData = [];

      while (startDate.isSameOrBefore(moment(endDate))) {
        const foundFlow = data.flows.find((p) =>
          moment(startDate).isSame(moment(p.time), intervalUnit)
        );

        // Found data on API
        if (foundFlow) {
          chartData.push([foundFlow.time, foundFlow.flow_count || 0]);

          // Adjust the interval to match the exact flow time if it doesn't align perfectly
          startDate = moment(foundFlow.time).add(interval, intervalUnit);
        }
        // 0 flows filler data
        else {
          chartData.push([startDate.valueOf(), 0]);
          startDate.add(interval, intervalUnit);
        }
      }

      // Stuff 0 for showing line on 1 data point response.
      if (chartData.length === 1) {
        chartData.splice(
          0,
          0,
          [
            chartData[0][0] -
            moment.duration(interval, intervalUnit).asMilliseconds(),
            0,
          ]
        );
      }

      setFlowsChartData(chartData);

      let startOfWeek = moment(chartData[chartData.length - 1][0])
        .weekday(1)
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      setSelectedWeekStartDate(startOfWeek.valueOf());
      console.log('set initial week for weekly charts');
    }
  }, [data, userSelectedRange]);



  const handleRangeSelection = (range: string) => {
    setUserSelectedRange(true);
    setSelectedRange(range);
    let timeFilter = appliedFilterTimestamp();

    // if (timeFilter.length > 0 && range !== 'selected') {
    //   onRangeChange(moment().subtract(60, 'days'), moment())
    // } 

    // if (timeFilter.length > 0 && range === 'selected') {
    //   onRangeChange(timeFilter[0], timeFilter[1]);
    // } 


  };




  return (
    <>
      <Popup
        key={selectedWeekStartDate}
        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
        open={true}
        closeOnDocumentClick={false}
        modal={true}
        closeOnEscape={false}
      // //lockScroll
      >
        <div className={`flow-trend-modal ${(selectedRange === 'weekly') && 'weekly'}`}>
          <div className="flow-close-container" onClick={handleClose}></div>
          <div className={"flow-trend-header"}>{chartTitle}</div>
          <div className={`flow-container ${(selectedRange === 'weekly') && 'weekly'}`}>
            <div className="range-selector">
              {/* {appliedFilterTimestamp().length > 0 && (
                <span
                  onClick={() => handleRangeSelection('selected')}
                  className={`btn-range ${selectedRange === 'selected' ? 'active' : ''}`}
                >
                 Selected time
                </span>
              )} */}
                {appliedFilterTimestamp().length > 0 && (
                <span
                  onClick={() => handleRangeSelection('all')}
                  className={`btn-range ${selectedRange === 'all' ? 'active' : ''}`}
                >
                 Selected time
                </span>
              )} 

              {appliedFilterTimestamp().length == 0 && (
                 <span onClick={() => handleRangeSelection('all')} className={`btn-range ${selectedRange === 'all' && 'active'}`}>All Time</span>
              )}
             
              <span onClick={() => handleRangeSelection('weekly')} className={`btn-range ${selectedRange === 'weekly' && 'active'}`}>Weekly</span>
              {(selectedRange === 'weekly') && (<div className="week-range-container"><span onClick={previousWeekClickHandler} className={`prev-next-week left ${(isPreviousRangeExceeded) && 'disable'}`}></span><span className={`week-range-label ${selectedRange === 'weekly' && 'active'}`} >{`${moment(selectedWeekStartDate)?.format('MMM DD YYYY')} - ${moment(selectedWeekStartDate)?.add(6, 'days')?.format('MMM DD YYYY')}`}</span><span onClick={nextWeekClickHandler} className={`prev-next-week right ${(isNextRangeExceeded) && 'disable'}`}></span></div>)}
            </div>
            {flowsChartData.length > 0
              ? renderFlowChart()
              : data?.flows?.length === 0 ? <div className="no-data">No data available</div> : <div className="loading-container"><div className='loading'></div></div>
            }
          </div>
        </div>
      </Popup>
    </>
  );
};

export default React.memo(FlowTrend);
