import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import Highcharts from 'highcharts';
import broken from 'highcharts/modules/broken-axis';
import HighchartsReact from 'highcharts-react-official';
import { IdAssetWidget } from '../../../types/response-types';
import { getResolutionMatch, getRoundedUnit } from '../../../utils/util-methods';
import HC_patternFill from "highcharts/modules/pattern-fill";
import { PostureSearchTemplate } from '../constants/PostureSearchTemplate';
import { getTimeBasedSearchQueryParam } from '../../../components/core/PostureFilter/posture-filter-utils';

HC_patternFill(Highcharts);
//broken(Highcharts);

interface Custom_Data {
	"title": string,
	"subtitle": string,
	"totalCount": number | undefined,
	"issueCount": number | undefined,
	"is_exclude": boolean,
}

type Props = {
	customData: Custom_Data,
	issues: IdAssetWidget | undefined
}

export const ReportByIdAsset = ({ customData, issues }: Props) => {
	let history = useHistory();
	const [chartInnerData, setChartInnerData] = useState<Highcharts.PointOptionsObject[]>([]);
	const [chartOuterData, setChartOuterData] = useState<Highcharts.PointOptionsObject[]>([]);
	const [chartInnerCloneData, setChartInnerCloneData] = useState<Highcharts.PointOptionsObject[]>([]);
	const [chartInnerEmptyIssues, setChartInnerEmptyIssues] = useState<Highcharts.PointOptionsObject[]>([]);

	function filterZeroData(data) {
		return data.filter(point => point.y > 0);
	}


	const calcData = () => {
		let innerData = [];
		let outerData = [];
		let innerCloneData: any = [];
		let innerCloneEmptyIssues: any = [];
		let colorsData: any = [];
		let index = 0;
		let chartData: any = issues;
		if (chartData.length <= 2) {
			colorsData = ["#454545", "#B5B5B5"]
		} else if (chartData.length <= 4) {
			colorsData = ["#454545", "#B5B5B5", "#E5E5E5", "#F0F0F0"]
		} else {
			colorsData = ["#454545", "#747474", "#B5B5B5", "#DBDBDB", "#E5E5E5", "#F0F0F0"]
		}
		if (customData.issueCount) {
			// Remove Jugaad Logic for legends	
			innerCloneEmptyIssues.push({
				y: 10,
				events: {
					legendItemClick: function (e: any) {
						setTimeout(() => handleWithIncidentClick());
						return true;
					}
				},
				name: "With Incidents",
				description: colorsData[2],
				id: customData.issueCount,
				color: {
					pattern: {
						path: 'M 0 0 L 7 7 M 6 0 L 7 0 M 0 6 L 0 7',
						color: "#ED4B5C",
						width: 7,
						height: 7
					}
				}
			});
		}

		chartData.sort(function (a: any, b: any) {
			var keyA = customData.is_exclude ? a.element_count_internal : a.element_count,
				keyB = customData.is_exclude ? b.element_count_internal : b.element_count;
			// Compare the 2 dates
			if (keyA > keyB) return -1;
			if (keyA < keyB) return 1;
			return 0;
		});

		for (let ob in chartData) {
			if (chartData[ob].type != "") {
				innerData.push({
					y: customData.is_exclude ? chartData[ob].element_count_with_issues_internal : chartData[ob].element_count_with_issues,
					name: chartData[ob].type,
					description: colorsData[index],
					color: colorsData[index]
				})
				innerCloneData.push({
					y: customData.is_exclude ? chartData[ob].element_count_with_issues_internal : chartData[ob].element_count_with_issues,
					name: chartData[ob].type,
					description: colorsData[index],
					color: {
						pattern: {
							path: 'M 0 0 L 8 8 M 7 0 L 8 0 M 0 7 L 0 8',
							color: "#ED4B5C",
							width: 8,
							height: 8
						}
					}
				})
				outerData.push({
					y: customData.is_exclude ? chartData[ob].element_count_internal : (chartData[ob]?.element_count || 0),
					name: chartData[ob].type == 'Unknown User' ? 'Unresolved User' : chartData[ob].type,
					description: colorsData[index],
					id: customData.is_exclude ? chartData[ob].element_count_internal : (chartData[ob]?.element_count || 0),
					color: colorsData[index]
				})
			}
			index++;
		}

		const filteredChartOuterData = filterZeroData(outerData);
		const filteredChartInnerData = filterZeroData(innerData);
		const filteredChartInnerCloneData = filterZeroData(innerCloneData);
		const filteredChartInnerEmptyIssues = filterZeroData(innerCloneEmptyIssues)
		//Calculate Incidents Data
		setChartInnerData(filteredChartInnerData);
		setChartInnerCloneData(filteredChartInnerCloneData);
		setChartInnerEmptyIssues(filteredChartInnerEmptyIssues);
		//Calculate Flows Data 
		setChartOuterData(filteredChartOuterData);

	}

	useEffect(() => {
		if (issues) {
			calcData();
		}
	}, [customData.is_exclude])

	const handleWithIncidentClick = () => {
		if (customData.title === "IDENTITIES") {
			history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=is_external:false%2Bstatus:Open%2Brule_name:Compromised Password,Suspicious Outbound Access,Compromised User,Weak Password");
		} else if (customData.title === "ASSETS")
			history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=is_external:false%2Bstatus:Open%2Brule_name:Suspicious Inbound Access,Exposed Assets,Shadow Assets,Shadow Access,Lack of MFA,Auth Protocol Quality,Unknown SaaS Access");
		else if (customData.title == 'ACCESSES') {
			history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=is_external:false%2Bstatus:Open");
		} else if (customData.title == 'IDENTITY SYSTEMS') {
			history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=is_external:false%2Bstatus:Open%2Brule_name:Auth Protocol Quality,Shadow Identity Systems,Auth Hash Quality,Auth Hash Security,Suspected Directory/IdP Password Spray Attack,Suspected Directory/IdP Bot Attack,Suspected AD NTLM Relay Attack,Enumeration of AD Admins,Enumeration of AD Users");
		}
	}

	const options: Highcharts.Options = {
		chart: {
			width: window.matchMedia("(min-width: 2560px)").matches ? 590 : 590,
			height: window.matchMedia("(min-width: 2560px)").matches ? 180 : 180,
			type: 'pie',
			style: {
				fontFamily: 'Metropolis-Regular',
			},

		},
		credits: {
			enabled: false
		}
	};

	const [chartOptions, setChartOptions] = useState<Highcharts.Options>(options);

	useLayoutEffect(() => {
		//console.log(customData.totalCount)
		setChartOptions(
			{
				...chartOptions,
				title: {
					useHTML: true,
					text:
						`<div class="ia_center_area"><span class="font16">${getRoundedUnit(customData?.totalCount || 0)}</span>` +
						`<br><span class="font8"> ${customData.subtitle}</span></div>` +

						`<div class="ia_center_area margintop5"><span class="ia-tooltip-hover font16" style="color:#f28490;">${getRoundedUnit(customData?.issueCount || 0)} </span>` +
						`<br><span class="font8" style="color:#f28490;">with incidents</span></div>`,
					style: {
						fontFamily: "Metropolis-Regular",
						fontSize: window.matchMedia("(min-width: 2560px)").matches ? "12px" : "12px",
						color: "#000000",
					},
					verticalAlign: 'middle',
					floating: false,
					x: window.matchMedia("(min-width: 2560px)").matches ?
						(chartOuterData?.length == 0 && chartInnerData?.length == 0 ? 190 : 190) : 190,
					y: window.matchMedia("(min-width: 2560px)").matches ? 12 : 12,
				},
				tooltip: {
					shared: true,
					shadow: false,
					padding: 0,
					borderRadius: 0,
					borderWidth: 0,
					backgroundColor: 'transparent',
					useHTML: true,
					formatter: function () {
						let points: any = this?.point;
						let tooltipMarkup = "<table class='ia-tooltip-content'>";
						tooltipMarkup += "<tr style='color:" + (points.total === customData.issueCount ? '#ED4B5C' : '') + "'><td style='background-color:" + points.description +
							"'>&nbsp</td><td class='ia_tooltip_percent'><b>" + Math.round(points.percentage) + "%</b></td><td style='border-left:1px solid grey;'><b>" +
							getRoundedUnit(points.y) + '</b></td><td>' +
							points.name + (points.series.options.description === "Inner" ? 's with incidents' : 's') + '</td></tr>';
						tooltipMarkup += "</table>"
						return tooltipMarkup;
					}
				},
				legend: {
					enabled: true,
					layout: 'vertical',
					align: 'left',
					// floating: true,					
					y: getResolutionMatch(60, 20),
					verticalAlign: 'top',
					itemMarginTop: window.matchMedia("(min-width: 2560px)").matches ? 2 : 2,
					symbolRadius: 0,
					useHTML: false,
					labelFormatter: function () {
						const current_count: any = this.options.id;
						const total_count: any = customData.totalCount;
						const percent: any = (current_count / total_count) * 100;
						if (percent === 0) {
							return '';
						}
						return this.name + ' ( ' + (this.options?.id || 0) + ' | ' + Math.round(percent || 0) + '%)';

						// return `<div class="square-widget-container"><div class="square-widget" style="background-color:${this?.color ? this?.color :'#url(#highcharts-pattern-32-443b540ee59fab0)' }"></div>
						// <div class="square-widget-label" title="(${this.name}) (${this.options.id}  |  ${Math.round(percent)}%)"> ${this.name} (${this.options.id}  |  ${Math.round(percent)}%) </span></div>`;
					},
					squareSymbol: true,
					navigation: {
						enabled: false
					},
					symbolHeight: window.matchMedia("(min-width: 2560px)").matches ? 12 : 12,
					// symbolPadding: 0,
					// symbolWidth: 0,
					// symbolHeight: 0,
					width: window.matchMedia("(min-width: 2560px)").matches ? 250 : 250,
					itemStyle: {
						fontWeight: "normal",
						fontSize: window.matchMedia("(min-width: 2560px)").matches ? "11px" : "11px",
						lineHeight: window.matchMedia("(min-width: 2560px)").matches ? "12px" : "12px",
					},
					// events: {
					// 	itemClick: function (e) {
					// 		const reportType: any = e?.legendItem?.name;

					// 		if (customData.title === "IDENTITIES" && reportType === "User") {
					// 			console.log("ASDFASDF")
					// 			history.push({
					// 				search: `page=1&q=identity_is_known%3Atrue%2Bidentity_type%3A${reportType}`,
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		} else if (customData.title === "IDENTITIES" && reportType === "Unresolved User") {
					// 			history.push({
					// 				search: "page=1&q=identity_is_known%3Afalse%2Bidentity_type%3AUser",
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		} else if (customData.title === "IDENTITIES" && reportType === "Service") {
					// 			history.push({
					// 				search: "page=1&q=identity_is_known%3Atrue%2Bidentity_type%3AService",
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		} else if (customData.title === "IDENTITIES" && reportType === "Unresolved Service") {
					// 			history.push({
					// 				search: "page=1&q=identity_is_known%3Afalse%2Bidentity_type%3AService",
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		} else if (customData.title === "IDENTITIES") {
					// 			history.push({
					// 				search: "page=1&q=identity_type%3A" + reportType,
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		}
					// 		if (customData.title === "ASSETS")
					// 			history.push({
					// 				search: "page=1&q=asset_type%3A" + reportType,
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		if (customData.title === "ACCESSES") {
					// 			const identity_type: any = reportType.split("-")[0]
					// 			const asset_type: any = reportType.split("-")[1]
					// 			history.push({
					// 				search: "page=1&q=identity_type%3A" + identity_type + "%2Basset_type%3A" + asset_type,
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		}
					// 		if (customData.title === "IDENTITY SYSTEMS")
					// 			history.push({
					// 				search: "page=1&q=dir_type%3A" + reportType,
					// 				state: { breadcrumbId: 'IdentityPosture' }
					// 			});
					// 		return false;
					// 	}
					// }
				},
				series: [{
					type: "pie",
					center: ['80%', '50%'],
					showInLegend: true,
					innerSize: window.matchMedia("(min-width: 2560px)").matches ? 110 : 110,
					size: window.matchMedia("(min-width: 2560px)").matches ? 155 : 155,
					minSize: window.matchMedia("(min-width: 2560px)").matches ? 50 : 50,
					data: chartOuterData, // Use filtered data
					cursor: "pointer",
					point: {
						events: {
							click: function (event) {														
								
								const reportType: any = this.options.name;

								if (customData.title === "IDENTITIES" && reportType === "User") {
									return history.push({
										search: "page=1&q=identity_is_known%3Atrue%2Bidentity_type%3A" + reportType,
										state: { breadcrumbId: 'IdentityPosture' }
									});
								} else if (customData.title === "IDENTITIES" && reportType === "Unresolved User") {
									return history.push({
										search: "page=1&q=identity_is_known%3Afalse%2Bidentity_type%3AUser",
										state: { breadcrumbId: 'IdentityPosture' }
									});
								} else if (customData.title === "IDENTITIES" && reportType === "Service") {
									history.push({
										search: "page=1&q=identity_is_known%3Atrue%2Bidentity_type%3AService",
										state: { breadcrumbId: 'IdentityPosture' }
									});
								} else if (customData.title === "IDENTITIES" && reportType === "Unresolved Service") {
									history.push({
										search: "page=1&q=identity_is_known%3Afalse%2Bidentity_type%3AService",
										state: { breadcrumbId: 'IdentityPosture' }
									});
								} else if (customData.title === "IDENTITIES") {
									return history.push({
										search: "page=1&q=identity_type%3A" + reportType,
										state: { breadcrumbId: 'IdentityPosture' }
									});
								} else if (customData.title === "ASSETS")
									return history.push({
										search: "page=1&q=asset_type%3A" + reportType,
										state: { breadcrumbId: 'IdentityPosture' }
									}); else if (customData.title === "ACCESSES") {
										const identity_type: any = reportType.split("-")[0]
										const asset_type: any = reportType.split("-")[1]
										return history.push({
											search: "page=1&q=identity_type%3A" + identity_type + "%2Basset_type%3A" + asset_type,
										});
									} else if (customData.title === "IDENTITY SYSTEMS")
									return history.push({
										search: "page=1&q=dir_type%3A" + reportType,
										state: { breadcrumbId: 'IdentityPosture' }
									});
							},
							legendItemClick: function (e) {
								const reportType: any = this.options?.name;

								if (customData.title === "IDENTITIES" && reportType === "User") {
									console.log("ASDFASDF")
									history.push({
										search: `page=1&q=identity_is_known%3Atrue%2Bidentity_type%3A${reportType}`,
										state: { breadcrumbId: 'IdentityPosture' }
									});
								} else if (customData.title === "IDENTITIES" && reportType === "Unresolved User") {
									history.push({
										search: "page=1&q=identity_is_known%3Afalse%2Bidentity_type%3AUser",
										state: { breadcrumbId: 'IdentityPosture' }
									});
								} else if (customData.title === "IDENTITIES" && reportType === "Service") {
									history.push({
										search: "page=1&q=identity_is_known%3Atrue%2Bidentity_type%3AService",
										state: { breadcrumbId: 'IdentityPosture' }
									});
								} else if (customData.title === "IDENTITIES" && reportType === "Unresolved Service") {
									history.push({
										search: "page=1&q=identity_is_known%3Afalse%2Bidentity_type%3AService",
										state: { breadcrumbId: 'IdentityPosture' }
									});
								} else if (customData.title === "IDENTITIES") {
									history.push({
										search: "page=1&q=identity_type%3A" + reportType,
										state: { breadcrumbId: 'IdentityPosture' }
									});
								}
								if (customData.title === "ASSETS")
									history.push({
										search: "page=1&q=asset_type%3A" + reportType,
										state: { breadcrumbId: 'IdentityPosture' }
									});
								if (customData.title === "ACCESSES") {
									const identity_type: any = reportType.split("-")[0]
									const asset_type: any = reportType.split("-")[1]
									history.push({
										search: "page=1&q=identity_type%3A" + identity_type + "%2Basset_type%3A" + asset_type,
										state: { breadcrumbId: 'IdentityPosture' }
									});
								}
								if (customData.title === "IDENTITY SYSTEMS")
									history.push({
										search: "page=1&q=dir_type%3A" + reportType,
										state: { breadcrumbId: 'IdentityPosture' }
									});
								return false;
							}
						}
					},
					dataLabels: {
						enabled: false,
						connectorShape: "straight",
						style: {
							fontSize: '1em',
							fontWeight: 'normal',
							textTransform: 'uppercase'
						}
					},
					states: {
						hover: {
							brightness: 0,
							halo: {
								size: window.matchMedia("(min-width: 2560px)").matches ? 5 : 5,
								opacity: 0.8
							},
						},
						inactive: {
							opacity: 1
						}
					}
				}, {
					type: "pie",
					innerSize: window.matchMedia("(min-width: 2560px)").matches ? 70 : 70,
					size: window.matchMedia("(min-width: 2560px)").matches ? 105 : 105,
					minSize: window.matchMedia("(min-width: 2560px)").matches ? 50 : 50,
					center: ['80%', '50%'],
					dataLabels: {
						enabled: false
					},
					states: {
						hover: {
							halo: {
								size: window.matchMedia("(min-width: 2560px)").matches ? 4 : 4,
								opacity: 0.8
							}
						},
						inactive: {
							opacity: 1
						}
					},
					data: chartInnerData,
					description: "Inner"
				}, {
					type: "pie",
					innerSize: window.matchMedia("(min-width: 2560px)").matches ? 70 : 70,
					size: window.matchMedia("(min-width: 2560px)").matches ? 105 : 105,
					minSize: window.matchMedia("(min-width: 2560px)").matches ? 50 : 50,
					dataLabels: {
						enabled: false
					},
					cursor: "pointer",
					center: ['80%', '50%'],
					point: {
						events: {
							legendItemClick: function (e) {
								return false;
							},
							click: function (event) {
								const bread = { breadcrumbId: 'PostureWithIncident', prevPath: history.location.pathname };
								if (customData.title === "IDENTITIES" && this.options.name != "Unknown User")
									return history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=status:Open%2Bs_type%3A" + this.options.name + '%2Brule_name:Compromised Password,Suspicious Outbound Access,Compromised User,Weak Password', bread);
								else if (customData.title === "ASSETS")
									return history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=status:Open%2Bd_type%3A" + this.options.name + '%2Brule_name:Suspicious Inbound Access,Exposed Assets,Shadow Assets,Shadow Access,Lack of MFA,Auth Protocol Quality,Unknown SaaS Access', bread);
								else if (customData.title == 'ACCESSES') {
									if (this.options.name?.toString().includes('-')) {
										const names = this.options.name.toString().split('-');
										return history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=status:Open%2Bd_type%3A" + names[1] + '%2Bs_type:' + names[0]);
									}
								}
								else if (customData.title == 'IDENTITY SYSTEMS') {
									return history.push("/issues?disable_filter=true&hard_refresh=false&page=1&q=status:Open%2Bd_type%3A" + this.options.name + '%2Brule_name:Auth Protocol Quality,Shadow Identity Systems,Auth Hash Quality,Auth Hash Security,Suspected Directory/IdP Password Spray Attack,Suspected Directory/IdP Bot Attack,Suspected AD NTLM Relay Attack,Enumeration of AD Admins,Enumeration of AD Users', bread);

								}


								//	console.log(this.options.name);
							}
						},
					},
					states: {
						hover: {
							halo: {
								size: 0,
								opacity: 0.8
							}
						},
						inactive: {
							opacity: 1
						}
					},
					data: chartInnerCloneData,
					description: "Inner"
				}, {
					type: "pie",
					showInLegend: true,
					innerSize: window.matchMedia("(min-width: 2560px)").matches ? 0 : 0,
					size: window.matchMedia("(min-width: 2560px)").matches ? 0 : 0,
					minSize: 0,
					center: ['80%', '50%'],
					dataLabels: {
						enabled: false
					},
					states: {
						hover: {
							halo: {
								size: 0,
								opacity: 0.8
							}
						},
						inactive: {
							opacity: 1
						}
					},
					data: chartInnerEmptyIssues,
					description: "Inner"
				}]
			}

		)

	}, [chartInnerData, chartOuterData])


	const chartRef = useRef<{
		chart: Highcharts.Chart;
		container: React.RefObject<HTMLDivElement>;
	}>(null);

	return (
		<>
			<div className="idasset_chart">
				<div className="idasset_chart_title report-container-title">
					<label>{customData.title}</label>
				</div>
				<div className='chart-container report-container' style={{ maxWidth: '100%' }}>
					<HighchartsReact
						ref={chartRef}
						highcharts={Highcharts}
						options={chartOptions}
					/>
				</div>
			</div>
		</>
	)
}