export type AddMfaConfigState = {
    provider_id: string | undefined,
    provider: string | undefined,
    factor: string | undefined,
    enrollTime: number | undefined,
    enrollUnit: string | undefined,
    authTime: number | undefined,
    authUnit: string | undefined
}

export type CompromisedPasswordConfigState = {
    commonPasswordFlag: boolean | undefined,
    feedPasswordFlag: boolean | undefined,
    feeds: Array<Feed> | undefined,
    hashFlag: boolean | undefined,
    hashFeeds: {
        websiteName: string | undefined,
        isChecked: boolean | undefined,
    }[] | undefined
}

export type CompromisedUserConfigState = {
    name: string | undefined,
    isChecked: boolean | undefined
}[] | undefined

export type Feed = {
    name: string | undefined,
    isChecked: boolean | undefined
}

export type CredentialQualityConfigState = {
    hashFlag?: boolean | undefined,
    hashValue?: number | undefined,
    saltFlag?: boolean | undefined,
    saltValue?: number | undefined,
    algoFlag?: boolean | undefined,
    algoValue?: CredentialQualityConfigAlgo[] | undefined,
    legacyProcFlag?: boolean | undefined,
    legacyProcValue?: CredentialQualityConfigProtocol[] | undefined,
    commonProcFlag?: boolean | undefined,
    commonProcValue?: CredentialQualityConfigProtocol[] | undefined
}

export type CredentialQualityConfigAlgo = {
    name: string,
    isChecked: boolean
}

export type CredentialQualityConfigProtocol = {
    name: string,
    isChecked: boolean
}

export type CredentialQualityConfigProps = {
    parentState: CredentialQualityConfigState | undefined,
    isSavedOnce: boolean,
    getState: (state: CredentialQualityConfigState | undefined) => void,
    defaultState: CredentialQualityConfigState | undefined
}

export type HashQualityConfigState = {
    hashFlag?: boolean | undefined,
    hashValue?: number | undefined,
    saltFlag?: boolean | undefined,
    saltValue?: number | undefined,
    algoFlag?: boolean | undefined,
    algoValue?: CredentialQualityConfigAlgo[] | undefined,
    legacyProcFlag?: boolean | undefined,
    legacyProcValue?: CredentialQualityConfigProtocol[] | undefined,
    commonProcFlag?: boolean | undefined,
    commonProcValue?: CredentialQualityConfigProtocol[] | undefined
}

export type HashQualityConfigProps = {
    parentState: HashQualityConfigState | undefined,
    isSavedOnce: boolean,
    getState: (state: HashQualityConfigState | undefined) => void,
    defaultState: HashQualityConfigState | undefined
}

export type HashSecurityConfigState = {
    hashFlag?: boolean | undefined,
    hashValue?: number | undefined,
    saltFlag?: boolean | undefined,
    saltValue?: number | undefined,
    algoFlag?: boolean | undefined,
    algoValue?: CredentialQualityConfigAlgo[] | undefined,
    legacyProcFlag?: boolean | undefined,
    legacyProcValue?: CredentialQualityConfigProtocol[] | undefined,
    commonProcFlag?: boolean | undefined,
    commonProcValue?: CredentialQualityConfigProtocol[] | undefined
}

export type HashSecurityConfigProps = {
    parentState: HashSecurityConfigState | undefined,
    isSavedOnce: boolean,
    getState: (state: HashSecurityConfigState | undefined) => void,
    defaultState: HashSecurityConfigState | undefined
}

export type MfaConfigState = {
    servers: {
        server: string;
        isChecked: boolean;
    }[];
    idpFlag: boolean;
}

export type ProtocolQualityConfigState = {
    hashFlag?: boolean | undefined,
    hashValue?: number | undefined,
    saltFlag?: boolean | undefined,
    saltValue?: number | undefined,
    algoFlag?: boolean | undefined,
    apq_ignore_private_ip?: boolean | undefined,
    algoValue?: CredentialQualityConfigAlgo[] | undefined,
    legacyProcFlag?: boolean | undefined,
    legacyProcValue?: CredentialQualityConfigProtocol[] | undefined,
    commonProcFlag?: boolean | undefined,
    commonProcValue?: CredentialQualityConfigProtocol[] | undefined
}

export type ProtocolQualityConfigProps = {
    parentState: ProtocolQualityConfigState | undefined,
    isSavedOnce: boolean,
    apq_ignore_private_ip: boolean,
    getState: (state: ProtocolQualityConfigState | undefined) => void,
    defaultState: ProtocolQualityConfigState | undefined
}

export type ShadowIdentityConfigState = {
    skip_mfa_match: boolean
} | undefined


export type ShadowIdentityConfigProps = {
    parentState: ShadowIdentityConfigState,
    isSavedOnce: boolean,
    getState: (state: ShadowIdentityConfigState) => void,
    defaultState: ShadowIdentityConfigState
}

export type WeakPasswordConfigState = {
    isTimeChecked: boolean | undefined,
    duration: number | undefined,
    unit: string | undefined,
    isDollarChecked: boolean | undefined,
    dollar: number | undefined
}

export type SuspiciousInboundConfigState = {
    ip_rep_threshold: number | undefined
} | undefined

export type SuspiciousInboundConfigProps = {
    parentState: SuspiciousInboundConfigState | undefined,
    isSavedOnce: boolean,
    getState: (state: SuspiciousInboundConfigState | undefined) => void,
    defaultState: SuspiciousInboundConfigState | undefined,
    formType?: string
}

export type SuspiciousOutboundConfigState = {
    ip_rep_threshold: number | undefined
} | undefined

export type SuspiciousOutboundConfigProps = {
    parentState: SuspiciousOutboundConfigState | undefined,
    isSavedOnce: boolean,
    getState: (state: SuspiciousOutboundConfigState | undefined) => void,
    defaultState: SuspiciousOutboundConfigState | undefined,
    formType?: string
}

export type DailyDeviationConfig = {
    is_checked: boolean,
    value?: number,
    percent?: number,
    operator?: string
}

export type DailyDeviationState = {
    today_flow_count_7_day_avg: DailyDeviationConfig,
    today_flow_count_gt_7_day_max: DailyDeviationConfig,
    today_flow_count_lt_7_day_min: DailyDeviationConfig,
    today_flow_count_gt_yesterday: DailyDeviationConfig,
    today_flow_count_gt_count: DailyDeviationConfig
}


export type AccessToUnauthCountriesState = {
    unauthorized_countries: {
        operator: string,
        countries: string[] | undefined
    }
}

export interface SuspectedBruteForceState {
    dir_params: {
        param_durations: [
            {
                param_name: string,
                duration: string
            }
        ],
        user_auth_fail_count_today: number | undefined,  // should be present only if time_duration is set to 'day'
        // user_bad_password_count_curhour: number | undefined, // should be present only if time_duration is set to 'hour'
        dir_bad_password_nuser_curhour: number | undefined
    }
}

export type SuspectedDirBotAttackState = {
    dir_params: {
        param_durations: [
            {
                param_name: string,
                duration: string
            },
            {
                param_name: string,
                duration: string
            }
        ],
        user_auth_fail_nhosts_today: number,
        user_auth_fail_count_today: number
    }
}

export type NTLMRelayAttackState = {
    dir_params: {
        dir_ntlm_relay_count_today: number,
        param_thresholds: Array<DirParamThreshold>
    }
}

export type LackOfMFAConfigState = {
    mfa_timeout: number,
    mfa_servers: Array<string>
}

export type SuspectedPassSprayState = {
    dir_params: {
        dir_bad_password_nuser_curhour: number
    }
}

export type EnumAdAdminState = {
    dir_params: {
        dir_bad_username_count_curhour: number | undefined
        dir_bad_username_nadmin_curhour: number | undefined,
        param_thresholds: Array<DirParamThreshold>
    }
}

export type EnumAdUserState = {
    dir_params: {
        dir_bad_username_count_curhour: number | undefined
        dir_bad_username_nuser_curhour: number | undefined,
        param_thresholds: Array<DirParamThreshold>
    }
}

export type UnauthLoginTimeState = {
    dir_params: {
        user_unauthorized_time_count_curhour: number
    }
}

export type DirParamThreshold = {
    param_name: string;
    threshold_type: string;
    threshold_confidence: string | undefined;
    allow_ml_modify: boolean;
};

export const ThresholdType = {
    MANUAL: 'manual',
    AUTOMATIC: 'automatic'
}

export type UnauthLoginDeviceState = {
    dir_params: {
        user_unauthorized_device_count_curhour: number
    }
}

export type SusAttackExpiredState = {
    dir_params: {
        user_expired_logon_count_curhour: number,
        param_thresholds: Array<DirParamThreshold>
    }
}

export type SusAttackDisabledState = {
    dir_params: {
        user_disabled_logon_count_curhour: number
    }
}

export type SusAttackLockedState = {
    dir_params: {
        user_locked_logon_count_curhour: number,
        param_thresholds: Array<DirParamThreshold>
    }
}

export type AccountTakeoverState = {
    account_takeover_params: {
        displacementFlag?: boolean,
        displacementValue?: number,
        displacementUnit?: string,
        distanceTraveledFlag?: boolean,
        distanceTraveledValue?: number,
        distanceTraveledUnit?: string,
        newIspFlag?: boolean,
        newIpRangeFlag?: boolean
    }
}

export interface SuspectedAccountSharingState {
    session_token_params: {
        assertion_timeout: number,
        period_timeout_unit: string
    }
}

export type ShadowDirectoryState = {
    IdentitySystemCategories: number[]
}