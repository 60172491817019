import React, { useCallback, useEffect, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts/highstock";
import { RiskLevelData } from "../../../types/response-types";
import "./RiskTrendIncidentWidget.scss";
import { useHistory } from "react-router-dom";
import { getRiskLevelFromColor } from "../../../utils/risk-level";
import RiskChart from "./RiskChart";
import ChartTrend from "../chart-trend/ChartTrend";
import { convertToCommaValue, getRoundedUnit } from "../../../utils/util-methods";

type Props = {
    irl: any | undefined | null;
    widgetId: string | undefined | null;
};

const RiskColor = new Map([
    ["critical", "#fa1262"],
    ["high", "#fd7122"],
    ["medium", "#fcbf2e"],
    // ["low", "#cccccc"],
]);

const getPostureLinkFromEntityType = (eType: string = '') => {
    const entity = eType.trim().toLowerCase();

    switch (entity) {
        case 'identity': return '/posture/identities';
        case 'asset': return '/posture/assets';
        case 'directory': return '/posture/directory';
        default: return '/posture/assets';
    }
}

const RiskTrendIncidentWidget = ({ irl, widgetId }: Props) => {
    const [riskDataList, setRiskDataList] = useState<{ chartData: any; color: string, riskName: string }[]>([]);
    const history = useHistory();
    const handleOnBarClick = useCallback((title: string = '', rule_name?: string, entityType: string = '') => {
        let url;
        if (rule_name === 'Shadow External Access') {
            url = `/issues?q=status%3AOpen%2Brule_name%3AShadow%20External%20Access%2Brisk:${title}&sort_by=issue_flows_count&order_by=desc`;
        } else {
            url = getPostureLinkFromEntityType(entityType).concat(`?q=rule_name:${rule_name}%2Brisk:${title}&order_by=desc&sort_by=flow_count`);
        }
        history.push(url, { breadcrumbId: 'RiskLevelPosture' });
    }, []);

    useEffect(() => {
        const chartDataList = [];
        for (let entry of RiskColor) {
            // the same as of recipeMap.entries()
            const [key, value] = entry;
            if (irl?.[key] && irl?.[key]?.rule_issues && irl?.[key]?.rule_issues?.length > 0) {
                chartDataList.push({ chartData: irl?.[key]?.rule_issues, color: value, riskName: key, count: irl?.[key]?.count, created: irl?.[key]?.created_issues, closed: irl?.[key]?.closed_issues });
            }
            else {
                chartDataList.push({ chartData: [], color: value, riskName: key, count: 0, created: 0, closed: 0 });
            }
        }
        setRiskDataList(chartDataList);
    }, [irl]);

    const renderChartTrend = (last_7days_created = 0, last_7days_closed = 0, trendClickHandler: Function) => {
        const markerValueActual = last_7days_created - last_7days_closed;
        const [iconClass, textClass, markerValue] = markerValueActual <= 0 ? ['down', 'green-text', Math.abs(markerValueActual)] : ['up', 'red-text', Math.abs(markerValueActual)];

        return <ChartTrend {...{ iconClass, textClass, markerValue: markerValue, last_7days_created: last_7days_created, trendClickHandler, trendClass:'no-cursor' }} />
    }

    return (
        <>
            <div className="risk-wise-incident-container">
                {riskDataList?.length > 0 ? (
                    <div className="risk-wise-incident-content">
                        <div className="rwic-total-section border-right">
                            <div className="total-incident-marker">
                                <span className='risk-incident-title'>Total Issues</span>
                                <div className="marker-container">
                                    <span className="total-risk-count">{getFormatedNumeric(irl?.total?.count || 0, 6)}</span>
                                    <span className="total-risk-marker">{renderChartTrend(irl?.total?.created_issues, irl?.total?.closed_issues, () => { })}</span>
                                </div>
                            </div>
                        </div>
                        {riskDataList?.map((chartProps) => {
                            return (
                                <div className={`rwic-all-risk-section border-right typeof-${chartProps.riskName}`}>
                                    <div className="risk-incident-marker">
                                        <span className={`risk-incident-title dot-${chartProps.riskName}`}>{chartProps.riskName}</span>
                                        <div className="marker-container">
                                            <span className="total-risk-count">{getFormatedNumeric(chartProps?.count, 6)}</span>
                                            <span className="total-risk-marker">
                                                {renderChartTrend(chartProps?.created, chartProps?.closed, () => { })}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="risk-incident-chart">
                                        <RiskChart {...chartProps} onBarClick={handleOnBarClick.bind(null, chartProps.riskName)} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <div className="risk-wise-incident-content">
                        <div className="rwic-total-section border-right">
                            <div className="total-incident-marker">
                                <span className='risk-incident-title'>Total Issues</span>
                                <div className="marker-container">
                                    <span className="total-risk-count">{getFormatedNumeric(irl?.total?.count || 0, 6)}</span>
                                    <span className="total-risk-marker">{renderChartTrend(irl?.total?.created_issues, irl?.total?.closed_issues, () => { })}</span>
                                </div>
                            </div>
                        </div>
                        {Array.from(RiskColor.keys()).map((key) => {

                            return (
                                <div className={`rwic-all-risk-section border-right typeof-${key}`}>
                                    <div className="risk-incident-marker">
                                        <span className={`risk-incident-title dot-${key}`}>{key}</span>
                                        <div className="marker-container">
                                            <span className="total-risk-count">{getFormatedNumeric(0, 6)}</span>
                                            <span className="total-risk-marker">
                                                {renderChartTrend(0, 0, () => { })}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="risk-incident-chart">

                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        </>
    );
};
function getFormatedNumeric(num: number, len: number) {
    try {
        let val = "0";
        if (num && num !== undefined) {
            if (num?.toString()?.length || 0 < len) {
                val = convertToCommaValue(num || 0)
            }
            else {
                val = getRoundedUnit(num)
            }
        }
        return val;
    }
    catch (err) {
        console.log(err);
        return num.toString();
    }
}
export default RiskTrendIncidentWidget;
