import React, { useState, useEffect, useMemo, useRef } from "react";
import Popup from "reactjs-popup";
import "./RecentAccessNew.css";
import lowRiskIcon from "../../images/Gray_Risk_Dot.png";
import mediumRiskIcon from "../../images/yellow_dot.png";
import highRiskIcon from "../../images/orange_dot.png";
import criticalRiskIcon from "../../images/pink_outline_dot.png";
import { IssueFlow, IssueObj, RecentIssuesObj } from "../../types/response-types";
import { getRisk } from "../../utils/risk-level";
import moment from "moment";
import { useHistory } from "react-router";
import { AxiosError } from "axios";
import { Api } from "../Axios";
import { useToasts } from "../../components/core";
import { convertToCommaValue, deepCopyFunction, getResolutionMatch, scrambleIt } from "../../utils/util-methods";
import { DashboardRuleMap } from "../../constants/dashboard-rule";

let issueBuffer: IssueObj[] = [];

type Props = {
    recent_issues_prop: RecentIssuesObj | undefined;
};


const Array15 = [...Array(8).keys()];
const array8 = [0,1,2,3,4,5,6,7];
const generateLines = () => {
    const list = [];
    for (let s = 1; s < 5; s++) {
        for (let d = 1; d < 4; d++) {
            for (let line = 1; line < 9; line++) {
                list.push(`s${s}d${d}_line${line}`);
            }
        }
    }
    
    return list;
};
const dummyLines = generateLines();

const RecentAccessNew = ({ recent_issues_prop }: Props) => {
    const [issueOpen, setIssueOpen] = useState(false);
    const [recentIssues, setRecentIssues] = useState<any>([]);
    const [issue, setIssue] = useState<IssueObj | undefined>();
    const [showFlow, setShowFlow] = useState(false);
    // const [propsUpdate, setPropsUpdate] = useState(false);
    const requestRef = React.useRef<number>();

    const [randomNumberList, setRandomNumberList] = useState(scrambleIt(array8));
    const pointsCollectionRef = useRef(
        Array15.map((p) => {
            return {};
        })
    );
    const [pointCollection, setPointCollection] = useState([]);

    let history = useHistory();

    let maxLeftMargin = getResolutionMatch(1000, 500);
    const incMargin = getResolutionMatch(0.6, 0.3);

    let { addToast } = useToasts();

    const generateIndex = (data: IssueObj): any => {
        let index = Math.floor(Math.random() * 15);
        if (recentIssueIndex?.includes(index)) {
            return generateIndex(data);
        }
        return {
            _id: data._id,
            data: data,
            index: index,
            riskLevel: data.risk,
            //sourceDest : "s"+Math.floor((Math.random()*4 )+ 1)+"d"+Math.floor((Math.random()*3) + 1)+"_line"+(index+1)
            sourceDest: "s" + getSourceId(data.source_type) + "d" + getDestinationId(data.destination_type) + "_line" + (index + 1),
        };
    };

    const animateBall = (timestamp) => {
        const list = pointsCollectionRef.current;
        for (let i = 0; i < 9; i++) {
           const point = document.getElementsByClassName(`x${i}_line_${i}`)[0];
           const line = document.getElementById(list[i]?.sourceDest);
           if (point) {
               const pointStyles = window.getComputedStyle(point);
               if (pointStyles.visibility === "visible") {
                   let margin = parseFloat(pointStyles.marginLeft.replace(/px/, "")) || 0;
                   // if(line){
                       if(margin> -5 && margin < maxLeftMargin){
                           line.style.visibility = 'visible';  
                       }
                       else{
                           line.style.visibility = 'hidden';
                       }
                   // }
                   
                   point.style.marginLeft = margin + incMargin + "px";
                   // point.title = list[i].sourceDest;
                   // point.innerHTML = point.style.marginLeft;
               }
           }
        }
        requestRef.current = requestAnimationFrame(animateBall);
    };

    const movePoint = () => {
        requestAnimationFrame(animateBall);
    };
const updatePoints = ()=>{
    const list = pointsCollectionRef.current;
    if (issueBuffer.length > 0) {
        // use Random Indexes till it is exhausted

        if (randomNumberList.length > 0) {
            const data = issueBuffer[0];
            const index = randomNumberList[0];
            const line_url_img= "s" + getSourceId(data.source_type) + "d" + getDestinationId(data.destination_type) + "_line" + (index + 1)
            list[randomNumberList[0]] = {
                _id: data._id,
                data: data,
                index: index,
                riskLevel: data.risk,
                sourceDest: line_url_img,
                margin: 0,
            };
            
            setTimeout(() => {
                const line = document.getElementById(`${line_url_img}`);
                if(line){
                    line.style.visibility = 'visible';
                }
            }, 50);
            
            
            issueBuffer.splice(0, 1);
            randomNumberList.splice(0, 1);
        } else {
            for (let i = 0; i < list.length; i++) {
                let elem = list[i];
                if (issueBuffer.length > 0) {
                    const data = issueBuffer[0];
                    const pointElem = document.getElementsByClassName(`x${elem.index}_line_${elem.index}`)[0];
                    const styles = window.getComputedStyle(pointElem);
                    let margin = parseFloat(styles.marginLeft.replace(/px/, "")) || 0;
                    //const line = document.getElementById(`${elem.sourceDest}`);
                    // if(margin > -30 && margin<maxLeftMargin){
                    //     if(line) {
                    //         line.style.visibility = 'visible';
                    //     }
                    // }
                    // else{
                    //     if(line) {
                    //         line.style.visibility = 'hidden';
                    //     }
                    // }
                    
                    if (margin >= maxLeftMargin) {
                        const startMargin = Math.floor(Math.random() * 50) + 20;
                        pointElem.style.marginLeft =`-${startMargin}px`;
                        elem._id = data._id;
                        elem.data = data;
                        elem.index = i;
                        elem.riskLevel = data.risk;
                        elem.sourceDest = "s" + getSourceId(data.source_type) + "d" + getDestinationId(data.destination_type) + "_line" + (i + 1);
                        elem.margin = 0;
                        issueBuffer.splice(0, 1);
                    }
                }
            }
        }
        setPointCollection(deepCopyFunction(list));
    }
}
    useEffect(() => {
        setTimeout(() => {
            updatePoints();
        }, 100);
        
        const triggerIssues = setInterval(() => {
            updatePoints();
        }, 3500);
        movePoint();
        return () => {
            cancelAnimationFrame(requestRef.current);
            clearInterval(triggerIssues);
        };
    }, []);

    useEffect(() => {
        if (recent_issues_prop?.issues) {
            issueBuffer = [...issueBuffer, ...recent_issues_prop?.issues];
            issueBuffer.splice(150,50);
            // setPropsUpdate(true)
        }
    }, [recent_issues_prop]);

    const getSourceId = (source: string) => {
        if (source.toLocaleLowerCase() === "user") {
            return 1;
        } else if (source.toLocaleLowerCase() === "service") {
            return 2;
        } else if (source.toLocaleLowerCase() == "device") {
            return 2;
        } else if (source.toLocaleLowerCase() == "app") {
            return 2;
        }
    };

    const getDestinationId = (destination: string) => {
        if (destination.toLocaleLowerCase() === "service") {
            return 1;
        } else if (destination.toLocaleLowerCase() === "app") {
            return 2;
        } else if (destination.toLocaleLowerCase() === "device") {
            return 3;
        }
    };

    const getRiskIcon = (index: number) => {
        let risk = pointCollection?.find((issue: any) => issue.index === index)?.riskLevel;
        if (risk === 1) {
            return lowRiskIcon;
        } else if (risk === 2) {
            return mediumRiskIcon;
        } else if (risk === 3) {
            return highRiskIcon;
        } else if (risk === 4) {
            return criticalRiskIcon;
        }
    };

    const handleDotClick = (e: React.MouseEvent<HTMLDivElement>) => {
        let temp = pointCollection?.find((issue: any) => issue._id === +e.currentTarget.id).data as IssueObj;
        setIssue(temp);
        setIssueOpen(true);
    };

    const handleShowFlows = (id: any) => {
        // setShowFlow(prev=>!prev);
        history.push("/issues?page=1&q=id%3A" + id + "&disable_filter=true&sort_by=issue_flows_count&order_by=desc", { breadcrumbId: "RecentAccessIncidents" });
    };

    const handleCloseIssue = (e: React.MouseEvent<HTMLDivElement>) => {
        let closureReason: string;
        if (e.currentTarget.id === "close_issue") {
            closureReason = "";
        } else if (e.currentTarget.id === "false_positive") {
            closureReason = "False Positive";
        }
        let ticketId = issue ? +issue._id : undefined;
        Api.post("/tickets", {
            issue_id: ticketId,
        })
            .then((res) => {
                if (res.status === 200) {
                    addToast("Ticket created successfully.", {
                        appearance: "success",
                        autoDismiss: true,
                    });
                    // console.log(ticketId)
                    if (closureReason === "") {
                        history.push("/tickets/closeissue/" + ticketId, ticketId);
                    } else {
                        history.push(`${"/tickets/report/" + ticketId}`, ticketId);
                    }
                }
            })
            .catch((err: AxiosError) => {
                if (err.response?.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: "error",
                        autoDismiss: true,
                    });
                }
            });
    };
    return (
        <>
            {pointCollection?.length === 0 ? (
                <div className="recent_container">&nbsp;</div>
            ) : (
                <div className="recent_container">
                    <Popup
                        open={issueOpen}
                        modal
                        closeOnEscape={false}
                        // //lockScroll
                        closeOnDocumentClick={false}
                        overlayStyle={{ zIndex: 15001, background: "rgba(227, 242, 253, .6)" }}
                        contentStyle={{
                            top: window.matchMedia("(min-width: 2560px)").matches ? -60 : -60,
                        }}
                    >
                        <div className="issue_popup fr">
                            <div className="issue_popup_close" onClick={() => setIssueOpen(false)}></div>
                            <div className="issue_title">
                                <strong>{issue?.matching_rule_name} </strong>
                                <br />
                                <span>
                                    {moment(issue?.modified_date).format("DD MMM,")} {moment(issue?.modified_date).format("hh:mm A")}
                                </span>
                            </div>
                            <div className="clrBoth margintop10px"></div>
                            <div className="issue_sub_section">
                                <strong>Source</strong>
                            </div>
                            <div className="issue_sub_section">
                                <strong>Destination</strong>
                            </div>
                            <div className="issue_sub_section">
                                <div className={issue?.source_type === "Service" ? "gear_icon_issue" : issue?.source_type === "Device" ? "device_icon" : issue?.source_type === "App" ? "settings_icon" : "user_icon"}></div>
                                {issue?.source_name}
                            </div>
                            <div className="issue_sub_section">
                                <div className={issue?.destination_type === "Service" ? "gear_icon_issue" : issue?.destination_type === "Device" ? "device_icon" : "settings_icon"}></div>
                                {issue?.destination_name}
                            </div>
                            <div className="shadow_button margintop20" onClick={() => handleShowFlows(issue?._id)}>
                                NUMBER OF FLOWS : {convertToCommaValue(issue?.issue_flows_count)}
                            </div>
                            <div className="pop_divider"></div>
                            <div className="pwd_status_title fl">{issue?.playbook_name}</div>
                            <div className="password_grid">
                                <div className="password_grid_bar fl">
                                    <div className={issue?.risk === 4 ? "compromise selected" : "compromise"}></div>
                                    <div className={issue?.risk === 3 ? "fair selected" : "fair"}></div>
                                    <div className={issue?.risk === 2 ? "weak selected" : "weak"}></div>
                                    <div className={issue?.risk === 1 ? "good selected" : "good"}></div>
                                </div>
                                <div className="password_type">{getRisk(issue?.risk!)} Risk</div>
                            </div>
                            <div className="shadow_button marginbottom20" onClick={() => handleShowFlows(issue?._id)}>
                                SEE INCIDENT DETAILS
                            </div>

                            <div className="action_taken_box">
                                <div className="action_taken_box_left">
                                    <strong>Action Taken: </strong>
                                    {issue?.actions?.map((action) => (
                                        <>
                                            <br />
                                            {action}
                                        </>
                                    ))}
                                </div>
                                <div className="action_taken_box_right">
                                    <strong>Create Ticket and...</strong>
                                    <br />
                                    <div className="shadow_button margintop10" id="close_issue" onClick={handleCloseIssue}>
                                        CLOSE INCIDENT
                                    </div>
                                    <div
                                        className="shadow_button"
                                        style={{ cursor: "not-allowed", color: "#ccc" }}
                                        // onClick={handleEditPlaybook}
                                    >
                                        EDIT PLAYBOOK
                                    </div>
                                    <div className="shadow_button" id="false_positive" onClick={handleCloseIssue}>
                                        REPORT FALSE POSITIVE
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Popup>

                    <div className="clrBoth"></div>
                    <h4 className="recent_issue_title">Recent Accesses with Incidents</h4>
                    <div className="chart_container">
                        <div className="request_col" style={{ visibility: pointCollection?.length > 0 ? "visible" : "hidden" }}>
                            <h4>Identities</h4>
                            <div className="request_source">
                                <div className="request_source_item">
                                    <strong>Users</strong>
                                    <div className="user_icon_source">&nbsp;</div>
                                    {/* <div className="recent_dot">&#11044;</div> */}
                                </div>
                                <div className="request_source_item">
                                    <strong>Services</strong>
                                    <div className="services_icon_source"></div>
                                    {/* <div className="recent_dot">&#11044;</div> */}
                                </div>
                                <div className="request_source_item">
                                    <strong>Devices</strong>
                                    <div className="devices_icon_source"></div>
                                    {/* <div className="recent_dot">&#11044;</div> */}
                                </div>
                                <div className="request_source_item">
                                    <strong>Apps</strong>
                                    <div className="apps_icon_source"></div>
                                    {/* <div className="recent_dot">&#11044;</div> */}
                                </div>
                            </div>
                        </div>

                        <div className="wave_box">
                            {/* Add Loop to generate lines */}
                            {
                                dummyLines.map(p=>{
                                	    const img_url = '/images/flows/1x/'+ p +'.png'
                                        //const displ = p.includes('s2d2')?{display:'block'}:{display:'none'}
                                		return (
                                		<div
                                        id={p}
                                		style={{backgroundImage: "url("+img_url+")"}}
                                		className="sd_line"></div>
                                	)
                                })

                                // pointCollection.map((issue: any) => {
                                    
                                //     const pointElem = document.getElementsByClassName(`x${issue.index}_line_${issue.index}`)[0];
                                //     let visible = true;
                                //     if(pointElem){
                                //         let styles = window.getComputedStyle(pointElem);
                                //         let margin = parseFloat(styles.marginLeft.replace(/px/, "")) || 0;
                                //         if(margin< -10 || margin > maxLeftMargin) {
                                //             visible = false;
                                //         }
                                //     }
                                    
                                //     const img_url = "/images/flows/1x/" + issue.sourceDest + ".png";
                                //     return <div id="" style={{ backgroundImage: "url(" + img_url + ")", visibility:`${(visible)?'visible':'hidden'}`}} className="sd_line"></div>;
                                // })
                            }
                            <div className="magnifier_box" style={{ visibility: pointCollection?.length > 0 ? "visible" : "hidden" }}>
                                <div
                                    className="point-ball x0_line_0"
                                    style={{ visibility: pointCollection?.map((issue: any) => issue.index).indexOf(0, 0) !== -1 ? "visible" : "hidden", background: `url(${getRiskIcon(0)}) no-repeat left` }}
                                    id={pointCollection?.find((issue: any) => issue.index === 0)?._id}
                                    onClick={handleDotClick}
                                ></div>
                                <div
                                    className="point-ball x1_line_1"
                                    style={{ visibility: pointCollection?.map((issue: any) => issue.index).indexOf(1, 0) !== -1 ? "visible" : "hidden", background: `url(${getRiskIcon(1)}) no-repeat left` }}
                                    id={pointCollection?.find((issue: any) => issue.index === 1)?._id}
                                    onClick={handleDotClick}
                                ></div>
                                <div
                                    className="point-ball x2_line_2"
                                    style={{ visibility: pointCollection?.map((issue: any) => issue.index).indexOf(2, 0) !== -1 ? "visible" : "hidden", background: `url(${getRiskIcon(2)}) no-repeat left` }}
                                    id={pointCollection?.find((issue: any) => issue.index === 2)?._id}
                                    onClick={handleDotClick}
                                ></div>
                                <div
                                    className="point-ball x3_line_3"
                                    style={{ visibility: pointCollection?.map((issue: any) => issue.index).indexOf(3, 0) !== -1 ? "visible" : "hidden", background: `url(${getRiskIcon(3)}) no-repeat left` }}
                                    id={pointCollection?.find((issue: any) => issue.index === 3)?._id}
                                    onClick={handleDotClick}
                                ></div>
                                <div
                                    className="point-ball x4_line_4"
                                    style={{ visibility: pointCollection?.map((issue: any) => issue.index).indexOf(4, 0) !== -1 ? "visible" : "hidden", background: `url(${getRiskIcon(4)}) no-repeat left` }}
                                    id={pointCollection?.find((issue: any) => issue.index === 4)?._id}
                                    onClick={handleDotClick}
                                ></div>
                                <div
                                    className="point-ball x5_line_5"
                                    style={{ visibility: pointCollection?.map((issue: any) => issue.index).indexOf(5, 0) !== -1 ? "visible" : "hidden", background: `url(${getRiskIcon(5)}) no-repeat left` }}
                                    id={pointCollection?.find((issue: any) => issue.index === 5)?._id}
                                    onClick={handleDotClick}
                                ></div>
                                <div
                                    className="point-ball x6_line_6"
                                    style={{ visibility: pointCollection?.map((issue: any) => issue.index).indexOf(6, 0) !== -1 ? "visible" : "hidden", background: `url(${getRiskIcon(6)}) no-repeat left` }}
                                    id={pointCollection?.find((issue: any) => issue.index === 6)?._id}
                                    onClick={handleDotClick}
                                ></div>
                                <div
                                    className="point-ball x7_line_7"
                                    style={{ visibility: pointCollection?.map((issue: any) => issue.index).indexOf(7, 0) !== -1 ? "visible" : "hidden", background: `url(${getRiskIcon(7)}) no-repeat left` }}
                                    id={pointCollection?.find((issue: any) => issue.index === 7)?._id}
                                    onClick={handleDotClick}
                                ></div>
                                {/* <div
                                    className="point-ball x8_line_8"
                                    style={{ visibility: pointCollection?.map((issue: any) => issue.index).indexOf(8, 0) !== -1 ? "visible" : "hidden", background: `url(${getRiskIcon(8)}) no-repeat left` }}
                                    id={pointCollection?.find((issue: any) => issue.index === 8)?._id}
                                    onClick={handleDotClick}
                                ></div>
                                <div
                                    className="point-ball x9_line_9"
                                    style={{ visibility: pointCollection?.map((issue: any) => issue.index).indexOf(9, 0) !== -1 ? "visible" : "hidden", background: `url(${getRiskIcon(9)}) no-repeat left` }}
                                    id={pointCollection?.find((issue: any) => issue.index === 9)?._id}
                                    onClick={handleDotClick}
                                ></div>
                                <div
                                    className="point-ball x10_line_10"
                                    style={{ visibility: pointCollection?.map((issue: any) => issue.index).indexOf(10, 0) !== -1 ? "visible" : "hidden", background: `url(${getRiskIcon(10)}) no-repeat left` }}
                                    id={pointCollection?.find((issue: any) => issue.index === 10)?._id}
                                    onClick={handleDotClick}
                                ></div>
                                <div
                                    className="point-ball x11_line_11"
                                    style={{ visibility: pointCollection?.map((issue: any) => issue.index).indexOf(11, 0) !== -1 ? "visible" : "hidden", background: `url(${getRiskIcon(11)}) no-repeat left` }}
                                    id={pointCollection?.find((issue: any) => issue.index === 11)?._id}
                                    onClick={handleDotClick}
                                ></div>
                                <div
                                    className="point-ball x12_line_12"
                                    style={{ visibility: pointCollection?.map((issue: any) => issue.index).indexOf(12, 0) !== -1 ? "visible" : "hidden", background: `url(${getRiskIcon(12)}) no-repeat left` }}
                                    id={pointCollection?.find((issue: any) => issue.index === 12)?._id}
                                    onClick={handleDotClick}
                                ></div>
                                <div
                                    className="point-ball x13_line_13"
                                    style={{ visibility: pointCollection?.map((issue: any) => issue.index).indexOf(13, 0) !== -1 ? "visible" : "hidden", background: `url(${getRiskIcon(13)}) no-repeat left` }}
                                    id={pointCollection?.find((issue: any) => issue.index === 13)?._id}
                                    onClick={handleDotClick}
                                ></div>
                                <div
                                    className="point-ball x14_line_14"
                                    style={{ visibility: pointCollection?.map((issue: any) => issue.index).indexOf(14, 0) !== -1 ? "visible" : "hidden", background: `url(${getRiskIcon(14)}) no-repeat left` }}
                                    id={pointCollection?.find((issue: any) => issue.index === 14)?._id}
                                    onClick={handleDotClick}
                                ></div> */}
                            </div>
                            {/* <div className="magnifier_box" style={{ visibility: pointCollection?.length > 0 ? "visible" : "hidden" }}>
                                {pointCollection.map((issue: any, idx) => {
                                    return <div
                                    key = {issue._id}
                                    className="point-ball"
                                    style={{ visibility: Object.keys(issue).length>0 ? "visible" : "hidden", 
                                            background: `url(${getRiskIcon(0)}) no-repeat left`, 
                                            marginLeft: (issue.margin || 0) + "px",
                                            top: ((idx*2)) + 'px'
                                        }}
                                    id={ issue._id }
                                    onClick={handleDotClick}
                                >{issue.margin}</div>;
                                })}

                            </div> */}
                        </div>

                        <div className="destination_col" style={{ visibility: pointCollection?.length > 0 ? "visible" : "hidden" }}>
                            <h4>Assets</h4>
                            <div className="destination_source">
                                <div className="destination_source_item">
                                    {/* <div className="recent_dot">&#11044;</div> */}
                                    <div className="services_icon_desti"></div>
                                    <strong>Services</strong>
                                </div>
                                <div className="destination_source_item">
                                    {/* <div className="recent_dot">&#11044;</div> */}
                                    <div className="apps_icon_desti"></div>
                                    <strong>Apps</strong>
                                </div>
                                <div className="destination_source_item">
                                    {/* <div className="recent_dot">&#11044;</div> */}
                                    <div className="device_icon_desti"></div>
                                    <strong>Devices</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default React.memo(RecentAccessNew);
