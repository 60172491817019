import React, { useLayoutEffect, useEffect, useRef, useState, useMemo, useCallback } from "react";
import HighchartsReact from "highcharts-react-official";
import * as Highcharts from "highcharts";
import { convertToCommaValue, dateToEpoch, getBreadCrumbTitleOnRuleType, getCustomLabelForRuleName, getIssueTypeIconClass, getIssueTypeTitle, getRoundedUnit, getRoundedUnitWithoutDot, getRuleName, trimAfterSecondWord, withDebounce, withThrottle } from "../../../utils/util-methods";
import "./donut-chart.scss";
import { ShadowIdentityData } from "../../../types/response-types";
import { useHistory } from "react-router";
import { any } from "prop-types";
import { AnyTxtRecord } from "dns";
import ChartTrend from "../chart-trend/ChartTrend";
import { Tooltip } from "@mui/material";
import { number } from "yargs";
import { CompromisedIcon, SuspiciousIcon, UnauthorizedIcon, UnknownIcon, UnmanagedIcon, VulnerableIcon } from "../../../Icons";
import { AMCountryFlag } from '../../../components/core/AMCountryFlag/AMCountryFlag';

type Props = {
    widgetId: string | undefined;
    ruleListProp: any;
    isWidgetDisabled: boolean;
    widgetTitle: string;
    groupData: any;
    selectedData: any;
};

const widEnum = {
    'wid2_0': 'id_access_infra',
    'wid2_1': 'shadow_activity',
    'wid2_2': 'unauthorised_access'
};

const getColorPallete = (count: number) => {
    if (count > 12) {
        return [
            "#f6f6f6",
            "#e6e6e6",
            "#d4d4d4",
            "#c3c3c3",
            "#b3b3b3",
            "#a1a1a1",
            "#909090",
            "#808080",
            "#6e6e6e",
            "#5d5d5d",
            "#4d4d4d",
            "#3b3b3b",
            "#2a2a2a",
            "#1a1a1a",
            "#080808"
        ];
    }
    else {
        return [
            "#e4e4e4",
            "#d1d1d1",
            "#bfbfbf",
            "#adadad",
            "#9b9b9b",
            "#898989",
            "#767676",
            "#646464",
            "#525252",
            "#404040",
            "#2e2e2e",
            "#1b1b1b"
        ];
    }
}

const DonutChart = ({ widgetId, ruleListProp, isWidgetDisabled, widgetTitle = '', groupData, selectedData }: Props) => {
    let history = useHistory();
    const [chartInnerData, setChartInnerData] = useState<Highcharts.PointOptionsObject[]>([]);
    const [chartOuterData, setChartOuterData] = useState<Highcharts.PointOptionsObject[]>([]);
    const [selectedIssue, setSelectedIssue] = useState<string>("");

    const [total_issue, total_asset] = useMemo(() => {
        let total_incidents = 0, total_asst = 0;
        const key = widEnum[widgetId || '']
        if (ruleListProp && widgetId && selectedData?.[key] && groupData) {
            const selectedWidgetList = selectedData[key].map((i) => i._id);
            groupData.forEach((obj: any) => {
                const ruleKey = obj.name;
                if (selectedWidgetList.includes(ruleKey)) {
                    total_incidents = total_incidents + (ruleListProp[ruleKey]?.incident_count || 0);
                    total_asst = total_asst + (ruleListProp[ruleKey]?.id_count || 0);
                } else {
                    const pbList = obj?.items?.map((i) => i._id) || [];
                    pbList.forEach(element => {
                        if (selectedWidgetList.includes(element)) {
                            total_incidents = total_incidents + (ruleListProp[element]?.incident_count || 0);
                            total_asst = total_asst + (ruleListProp[element]?.id_count || 0);
                        }
                    });
                }
            })
        }
        console.log(total_incidents, total_asst)
        return [total_incidents, total_asst];
    }, [ruleListProp, widgetId]);

    /*     const [total_issue, total_asset] = useMemo(() => {
            if (ruleListProp && widgetId) {
                return Object.keys(ruleListProp).reduce((a, c) => {
                    const total_incidents = a[0] + (ruleListProp[c]?.incident_count || 0);
                    const total_asset = a[1] + (ruleListProp[c]?.id_count || 0);
                    return [total_incidents, total_asset];
                }, [0, 0]);
            } else {
                return [0, 0];
            }
        }, [ruleListProp, widgetId]); */

    const totalShadowTrend = useMemo(() => {
        let totalLast_7days_closed = 0;
        let totalLast_7days_created = 0;
        try {
            Object.keys(ruleListProp || {}).forEach((objKey) => {
                const { last_7days_closed_id = 0, last_7days_created_id = 0 } = { last_7days_closed_id: 0, last_7days_created_id: 0, ...ruleListProp?.[objKey] };
                totalLast_7days_closed += last_7days_closed_id;
                totalLast_7days_created += last_7days_created_id;
            });
            return { totalLast_7days_created, totalLast_7days_closed };
        } catch (error) {
            console.log(error);
            return { totalLast_7days_created, totalLast_7days_closed };
        }
    }, [ruleListProp]);

    const options: Highcharts.Options = {
        chart: {
            marginTop: window.matchMedia("(min-width: 2560px)").matches ? 10 : 10,
            marginLeft: window.matchMedia("(min-width: 2560px)").matches ? 0 : 0,
            width: window.matchMedia("(min-width: 2560px)").matches ? 140 : 140,
            height: window.matchMedia("(min-width: 2560px)").matches ? 140 : 140,
            type: "pie",
            style: {
                fontFamily: "Metropolis-Regular",
                position: "inherit",
            },
            events: {
                load: function () {
                    var chart = this;

                    chart.customHalo = {
                        graphic: null,
                        index: null,
                    };
                },
            },
        },
        credits: {
            enabled: false,
        },
    };

    const calcData = (clickRuleName: any) => {
        let innerData: any = [];
        let outerData: any = [];
        let outerDataTotal = 0;
        let colorsData = getColorPallete(Object.keys(ruleListProp || {}).length);
        let colorsinnerData = ["#cccccc", "#fcbf2e", "#fd7122", "#fa1262"];

        if (total_issue! > 0) {
            let idx = 0;
            Object.keys(ruleListProp).map((keyName) => {
                const { id_count = 0, incident_count = 0, entity_type = '' } = ruleListProp[keyName] || {};
                const riskObj = ruleListProp[keyName]?.criticality || {};

                outerDataTotal += incident_count;

                //If incident_count is less than 3% then set minimum dummy data to 3% of totl_issues or else just incident_count ...
                let curr_incident_count_per = (incident_count / total_issue!) * 100;
                let one_percent = total_issue! / 100;
                let three_percent = Math.round(one_percent * 5);

                if (incident_count !== 0) {
                    const risk_name = isIssueType(selectedData, keyName) ? keyName : findNameById(groupData, keyName);
                    let risk = "";
                    Object.keys(riskObj)
                        .reverse()
                        .some((key, j) => {
                            if (riskObj[key] !== 0) {
                                risk = key === "1" ? "Low" : key === "2" ? "Medium" : key === "3" ? "High" : "Critical";
                            }
                        });
                    outerData.push({
                        y: curr_incident_count_per < 5 && curr_incident_count_per > 0 ? three_percent : incident_count,
                        custom: id_count,
                        name: risk_name,
                        color: colorsData[idx++],
                        description: risk_name,
                        className: incident_count,
                        sliced: false,
                        selected: false,
                        entityType: entity_type,
                        risk: risk
                    });
                }
            });

            Object.keys(ruleListProp).map((keyName, i) => {
                const riskObj = ruleListProp[keyName]?.criticality || {};
                const entityType = ruleListProp[keyName]?.entity_type || '';
                const { id_count = 0, incident_count = 0 } = ruleListProp[keyName] || {};
                //If incident_count is less than 3% then set minimum dummy data to 3% of totl_issues or else just incident_count ...
                let curr_incident_count_per = (incident_count / total_issue!) * 100;

                let one_percent = total_issue! / 100;
                let three_percent = Math.round(one_percent * 5);
                const totalShadowIssues = curr_incident_count_per < 5 && curr_incident_count_per > 0 ? three_percent : incident_count;

                Object.keys(riskObj)
                    .reverse()
                    .some((key, j) => {
                        if (riskObj[key] !== 0) {
                            const risk_name = isIssueType(selectedData, keyName) ? keyName : findNameById(groupData, keyName);
                            innerData.push({
                                y: totalShadowIssues,
                                description: key === "1" ? risk_name + " | Low" : key === "2" ? risk_name + " | Medium" : key === "3" ? risk_name + " | High" : risk_name + " | Critical",
                                color: colorsinnerData[parseInt(key) - 1],
                                className: riskObj[key],
                                name: risk_name,
                                sliced: false,
                                selected: false,
                                entityType: entityType
                            });
                            return true;
                        }
                        return false;
                    });
            });
        }
        setChartInnerData(innerData);
        setChartOuterData(outerData);
    };

    useEffect(() => {
        calcData("No");
        //alert('rerendering...')
    }, [ruleListProp, selectedData]);

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>(options);

    useLayoutEffect(() => {
        setChartOptions({
            ...chartOptions,
            title: {
                text: "",
            },
            tooltip: {
                enabled: true,
                useHTML: true,
                backgroundColor: "rgba(0,0,0,0)",
                padding: 0,
                hideDelay: 100,
                borderRadius: 10,
                borderWidth: 0,
                shadow: false,
                outside: true,
                style: {
                    maxWidth: window.matchMedia("(min-width: 2560px)").matches ? 200 : 200,
                },
                formatter: function () {
                    let points: any = this?.point;

                    const riskRule: any = points.name;
                    const isInner: any = points.series.name;
                    const riskLevel = riskRule.split("|")[0];
                    let tooltipMarkup = "";

                    if (isInner === "innerPie") {
                        let totalInnerSeries: any = 0;
                        points.series.data.map((item: any, index: any) => {
                            if (points.name === item.name) {
                                totalInnerSeries = totalInnerSeries + item.y;
                            }
                        });

                        const showPer = Math.round((points.y / totalInnerSeries) * 100);
                        tooltipMarkup =
                            "<div class='shw_sum_tooltip innerCircleTooltip'><table><tr><td style='background-color:" +
                            this.color +
                            "'><b>" +
                            points.className +
                            "</b></td><td>" +
                            points.description +
                            "</td></tr></table></div>";
                    } else {
                        tooltipMarkup =
                            "<div class='shw_sum_tooltip'><table><tr><td style='text-align:right;vertical-align:top;'>" +
                            "<b>" +
                            getRoundedUnit(points.custom) +
                            '</b></td><td style="text-align:left;">' +
                            riskLevel +
                            '</td></tr><tr><td style="text-align:right;"><b>' +
                            getRoundedUnit(points.className) +
                            '</b></td><td style="text-align:left;">Incidents <b>(' +
                            getRoundedUnit(this.percentage) +
                            "%)</b></td></tr></table></div>";
                    }
                    return tooltipMarkup;
                },
            },
            series: [
                {
                    type: "pie",
                    name: "outerPie",
                    innerSize: window.matchMedia("(min-width: 2560px)").matches ? 72 : 72,
                    size: window.matchMedia("(min-width: 2560px)").matches ? 120 : 120,
                    minSize: window.matchMedia("(min-width: 2560px)").matches ? 20 : 20,
                    borderWidth: window.matchMedia("(min-width: 2560px)").matches ? 1 : 1,
                    borderColor: "#fff",
                    cursor: "pointer",
                    data: chartOuterData,
                    slicedOffset: window.matchMedia("(min-width: 2560px)").matches ? 4 : 4,
                    //allowPointSelect: true,
                    dataLabels: {
                        enabled: false,
                    },
                    point: {
                        events: {
                            click: function (event) {
                                if (this?.entityType === "Country") {
                                    const { risk } = this.options;
                                    history.push(`/issues?disable_filter=true&page=1&q=rule_name%3A${this.name.trim()}%2Brisk%3A${risk.toLowerCase().trim()}%2Bstatus%3AOpen`);
                                } else {
                                    handleClick(this.name, this?.entityType);
                                }
                            },
                            mouseOver: function (event) {
                                setSelectedIssue("");
                                // reset customHalo selected.
                                if (chartRef?.current?.chart?.customHalo.graphic) {
                                    chartRef?.current?.chart?.customHalo?.graphic.destroy();
                                    chartRef.current.chart.customHalo.index = null;
                                    chartRef.current.chart.customHalo.graphic = null;
                                }

                                const el = document.getElementById(`scroll-${widgetId}-${this.name}`);
                                const allEl = document.getElementsByClassName("issue-header");
                                for (let i = 0; i < allEl?.length || 0; i++) {
                                    allEl[i]?.setAttribute("class", "header issue-header");
                                }
                                el?.setAttribute("class", "header highlight-issue issue-header");

                                setTimeout(() => {
                                    //el.parentNode.scrollTop = el.offsetTop; // jerky behaviour
                                    el?.scrollIntoView({ block: "nearest", behavior: "smooth", inline: 'start' });
                                }, 100);
                            },
                        },
                    },
                    states: {
                        hover: {
                            enabled: true,
                            brightness: 0,
                            halo: {
                                size: window.matchMedia("(min-width: 2560px)").matches ? 4 : 4,
                                opacity: 0.9,
                            },
                        },
                        inactive: {
                            opacity: 1,
                        },
                    },
                },
                {
                    type: "pie",
                    name: "innerPie",
                    innerSize: window.matchMedia("(min-width: 2560px)").matches ? 44.5 : 44.5,
                    size: window.matchMedia("(min-width: 2560px)").matches ? 72 : 72,
                    minSize: window.matchMedia("(min-width: 2560px)").matches ? 18.5 : 18.5,
                    cursor: "pointer",
                    borderWidth: window.matchMedia("(min-width: 2560px)").matches ? 1 : 1,
                    slicedOffset: window.matchMedia("(min-width: 2560px)").matches ? 4 : 4,
                    borderColor: "#fff",
                    point: {
                        events: {
                            click: function (event) {
                                const riskRule: any = this.options.description;
                                const riskLevel = riskRule.split("|");

                                // if(riskLevel[0].trim()==="Suspicious Outbound Access")
                                //     return history.push(`/posture/identities?hard_refresh=true&order_by=desc&page=1&q=rule_name%3A${riskLevel[0].trim()}%2Brisk%3A${riskLevel[1].toLowerCase().trim()}&sort_by=score`)
                                // if(riskLevel[0].trim()==="Shadow Directory")
                                //     return history.push(`/posture/directory?hard_refresh=true&order_by=desc&page=1&q=rule_name%3A${riskLevel[0].trim()}%2Brisk%3A${riskLevel[1].toLowerCase().trim()}&sort_by=score`)
                                // else
                                //     return history.push(`/posture/assets?hard_refresh=true&order_by=desc&page=1&q=rule_name%3A${riskLevel[0].trim()}%2Brisk%3A${riskLevel[1].toLowerCase().trim()}&sort_by=score`)
                                if (riskLevel[0].trim() === "Shadow Directory") {
                                    return history.push(`/issues?disable_filter=true&page=1&q=rule_name%3AShadow%20Assets%2Brisk%3A${riskLevel[1].toLowerCase().trim()}%2Bdir_present%3Atrue%2Bstatus%3AOpen`);
                                } else if (riskLevel[0].trim() === "Shadow External Access") {
                                    return history.push(`/issues?&disable_filter=true&page=1&q=rule_name%3AShadow%20External%20Access%2Brisk%3A${riskLevel[1].toLowerCase().trim()}%2Bstatus%3AOpen`);
                                } else return history.push(`/issues?disable_filter=true&page=1&q=rule_name%3A${riskLevel[0].trim()}%2Brisk%3A${riskLevel[1].toLowerCase().trim()}%2Bstatus%3AOpen`);
                            },
                        },
                    },
                    data: chartInnerData,
                    dataLabels: {
                        enabled: false,
                    },
                },
            ],
        });
    }, [chartInnerData, chartOuterData, ruleListProp, selectedData]);

    const chartRef = useRef<{
        chart: Highcharts.Chart;
        container: React.RefObject<HTMLDivElement>;
    }>(null);

    const isIssueType = (array: any[], name: string): boolean => {
        if (widgetId) {
            const key = widEnum[widgetId] + '_default_view';
            if (selectedData[key]) {
                return true;
            } else {
                const widId = widEnum[widgetId];
                const foundItem = selectedData[widId].find((item: any) => item._id === name);
                return foundItem ? foundItem.is_issue_type : true;
            }
        }
    }

    const findIDByName = (array: any[], name: string): string => {
        for (const obj of array) {
            const foundItem = obj.items.find((item: any) => item.name === name);
            if (foundItem) {
                return foundItem._id;
            }
        }
        return name;
    };

    const handleClick = useCallback((ruleType: string, entityType: string) => {
        let isIssue: boolean = false;
        isIssue = isIssueType(selectedData, ruleType);
        ruleType = isIssue ? ruleType : entityType === 'Country' ? findNameById(groupData, ruleType) : findIDByName(groupData, ruleType)
        let title = `All ${ruleType}`;
        title = getBreadCrumbTitleOnRuleType(ruleType);
        const id_type = getTitleOnEntityType(entityType);
        const bread = { breadcrumbId: id_type === 'countries' ? "ShadowActivityIncidents" : "ShadowActivityPosture", customData: { pageName: widgetTitle } };
        switch (id_type) {
            case 'assets': isIssue ? history.push(`/posture/assets?hard_refresh=true&order_by=desc&page=1&q=rule_name%3A${ruleType}&sort_by=flow_count`, bread) : history.push(`/posture/assets?hard_refresh=true&order_by=desc&page=1&q=asset_playbooks%3A${ruleType}&sort_by=flow_count`, bread);
                break;
            case 'identities': isIssue ? history.push(`/posture/identities?hard_refresh=true&order_by=desc&page=1&q=rule_name%3A${ruleType}&sort_by=flow_count`, bread) : history.push(`/posture/identities?hard_refresh=true&order_by=desc&page=1&q=identity_playbooks%3A${ruleType}&sort_by=flow_count`, bread);
                break;
            case 'directories': isIssue ? history.push(`/posture/directory?hard_refresh=true&order_by=desc&page=1&q=rule_name%3A${ruleType}&sort_by=flow_count`, bread) : history.push(`/posture/directory?hard_refresh=true&order_by=desc&page=1&q=directory_playbooks%3A${ruleType}&sort_by=flow_count`, bread);
                break;
            case 'countries': isIssue ? history.push("/issues?disable_filter=true&page=1&q=rule_name%3A" + ruleType + "%2Bstatus%3AOpen&sort_by=issue_flows_count&order_by=desc", bread) : history.push("/issues?disable_filter=true&page=1&q=pb_name%3A" + ruleType + "%2Bstatus%3AOpen&sort_by=issue_flows_count&order_by=desc", bread);
                break;
            default: history.push(`/posture/assets?hard_refresh=true&order_by=desc&page=1&q=rule_name%3A${ruleType}&sort_by=flow_count`, bread);
        }

    }, [selectedData]);


    const findNameById = (array: any[], idToFind: string): string => {
        for (const obj of array) {
            const foundItem = obj.items.find((item: any) => item._id === idToFind);
            if (foundItem) {
                return foundItem.name;
            }
        }
        return idToFind;
    }

    const handleIncidentCountClick = useCallback((evt: any) => {

        const bread = { breadcrumbId: "ShadowActivityIncidents", customData: { pageName: widgetTitle } };
        let ruleType = evt?.name || undefined;
        let isIssue: boolean = false;
        isIssue = isIssueType(selectedData, ruleType);
        ruleType = isIssue ? ruleType : findNameById(groupData, ruleType);
        // ruleType = isIssue ? ruleType : findNameById(groupData,ruleType)
        if (!evt) {
            const rules = getAllRuleNames();
            history.push(`/issues?disable_filter=true&page=1&q=rule_name%3A${rules}%2Bstatus%3AOpen&sort_by=issue_flows_count&order_by=desc`, bread);
        } else {
            // if (ruleType === "Shadow Directory")
            //     history.push("/issues?disable_filter=true&page=1&q=rule_name%3AShadow%20Assets%2Bstatus%3AOpen%2Bdir_present%3Atrue&sort_by=issue_flows_count&order_by=desc"
            //         , bread);
            // else
            if (ruleType === "Suspicious Outbound Access") history.push("/issues?disable_filter=true&page=1&q=rule_name%3A" + ruleType + "%2Bstatus%3AOpen&sort_by=issue_flows_count&order_by=desc", bread);
            else if (ruleType === "Shadow External Access") history.push("/issues?disable_filter=true&page=1&q=rule_name%3A" + ruleType + "%2Bstatus%3AOpen&sort_by=issue_flows_count&order_by=desc", bread);
            else isIssue ? history.push("/issues?disable_filter=true&page=1&q=rule_name%3A" + ruleType + "%2Bstatus%3AOpen&sort_by=issue_flows_count&order_by=desc", bread) : history.push("/issues?disable_filter=true&page=1&q=pb_name%3A" + ruleType + "%2Bstatus%3AOpen&sort_by=issue_flows_count&order_by=desc", bread);
        }
    }, [ruleListProp, selectedData]);

    const totalTrendClickHandler = () => {
        const bread = { breadcrumbId: "WidgetTrends", customData: { pageName: widgetTitle } };
        const dt = new Date();
        const endTime = dateToEpoch(dt.toString());
        const day = dt.getDate() - 7;
        dt.setDate(day); // set 7 days back date
        const startTime = dateToEpoch(dt.toString());
        const allRulesForCategory = Object.keys(ruleListProp || {}).map(p => p.replaceAll(' ', '%20')).join('%2C');
        history.push(
            `/issues?disable_filter=true&page=1&q=rule_name%3A${allRulesForCategory}%2Bfirst_flow_time%7C${startTime}%2C${endTime}%2Bstatus%3AOpen`, bread
        );
    };
    const trendClickHandler = (evt: any) => {
        const bread = { breadcrumbId: "WidgetTrends", customData: { pageName: widgetTitle } };
        let currentSelectedShadowActivity = encodeURI(evt || "Exposed Assets");
        const dt = new Date();
        const endTime = dateToEpoch(dt.toString());
        const day = dt.getDate() - 7;
        let isIssue: boolean = false;
        isIssue = isIssueType(selectedData, currentSelectedShadowActivity);
        currentSelectedShadowActivity = isIssue ? currentSelectedShadowActivity : findNameById(groupData, currentSelectedShadowActivity);
        dt.setDate(day); // set 7 days back date
        const startTime = dateToEpoch(dt.toString());
        isIssue ? history.push(`/issues?disable_filter=true&page=1&q=rule_name%3A${currentSelectedShadowActivity}%2Bfirst_flow_time%7C${startTime}%2C${endTime}%2Bstatus%3AOpen`, bread) : history.push(`/issues?disable_filter=true&page=1&q=pb_name%3A${currentSelectedShadowActivity}%2Bfirst_flow_time%7C${startTime}%2C${endTime}%2Bstatus%3AOpen`, bread);
    };
    const getColor = (issueKeyName: string): any => {
        const prop = chartOuterData.find((o) => o.name === issueKeyName);
        return prop?.color?.toString();
    };

    const highlightHoveredSlice = (name: string) => {
        setSelectedIssue(name);
    };
    const throttledMouseOverIssueHandler = withDebounce(highlightHoveredSlice, 100);

    useEffect(() => {
        const allEl = document.getElementsByClassName("issue-header");
        for (let i = 0; i < allEl?.length || 0; i++) {
            if (allEl[i].id === `scroll-${widgetId}-${selectedIssue}`) {
                allEl[i]?.setAttribute("class", "header highlight-issue issue-header");
                continue;
            }
            allEl[i]?.setAttribute("class", "header issue-header");
        }
        const slices = chartRef?.current?.chart?.series[0]?.data;
        const slice = slices?.find((p) => p.name === selectedIssue);
        const chrt = chartRef?.current?.chart;
        let point =
            chrt?.series[0]?.data?.find((p) => {
                return p.name === selectedIssue;
            }) || null;
        if (point) {
            let chart = point.series.chart;
            let shapeArgs = point.shapeArgs;
            let size = window.matchMedia("(min-width: 2560px)").matches ? 4 : 4;
            let opacity = 0.8;
            let path = chart.renderer.symbols.arc(shapeArgs.x + chart.plotLeft, shapeArgs.y + chart.plotTop, shapeArgs.r + size, shapeArgs.r + size, {
                innerR: shapeArgs.r - 1,
                start: shapeArgs.start,
                end: shapeArgs.end,
            });
            let drawNewGraphic = false;
            let arc;

            drawNewGraphic = chart.customHalo.index !== point.index ? true : false;

            if (!drawNewGraphic && chart.customHalo.graphic) {
                chart.customHalo.graphic.destroy();
                chart.customHalo.index = null;
                chart.customHalo.graphic = null;
            } else if (chart.customHalo.graphic) {
                chart.customHalo.graphic.destroy();
            }

            if (drawNewGraphic) {
                arc = chart.renderer
                    .path(path)
                    .attr({
                        fill: point.color,
                        opacity: opacity,
                    })
                    .add();

                chart.customHalo = {
                    index: point.index,
                    graphic: arc,
                };

                chart.customHalo.index = point.index;
            }
        }
    }, [selectedIssue, selectedData, ruleListProp]);

    const getTotalIncidentCount = () => {
        return total_issue;
/*         let totalIncidentCount = 0;
        Object.keys(ruleListProp).map((keyName, i) => {
            totalIncidentCount += ruleListProp[keyName]?.incident_count || 0;
        });
        return totalIncidentCount; */
    };

    const getLast7DaysIncidentCount = () => {
        let totalIncidentCount = 0;
        Object.keys(ruleListProp).map((keyName, i) => {
            totalIncidentCount += ruleListProp[keyName]?.last_7days_created_incident || 0;
        });
        return totalIncidentCount;
    };

    const getAllRuleNames = () => {
        let names = [] as any;
        Object.keys(ruleListProp).map((keyName, i) => {
            names.push(keyName);
        });

        return names.join(",");
    };

    const renderChartTrend = (last_7days_created_id = 0, last_7days_closed_id = 0, trendClickHandler: Function) => {
        const markerValueActual = last_7days_created_id - last_7days_closed_id;
        const [iconClass, textClass, markerValue] = markerValueActual <= 0 ? ['down', 'green-text', Math.abs(markerValueActual)] : ['up', 'red-text', Math.abs(markerValueActual)];
        return <ChartTrend {...{ iconClass, textClass, markerValue, last_7days_created: last_7days_created_id, trendClickHandler }} />
    }

    const renderIcon = (type: String, color: string) => {
        switch (type) {
            case 'vulnerable-icon': return <VulnerableIcon color={color} />;
            case 'compromised-icon': return <CompromisedIcon color={color} />

            case 'unmanaged-icon': return <UnmanagedIcon color={color} />;

            case 'unknown-icon': return <UnknownIcon color={color} />;

            case 'suspicious-icon': return <SuspiciousIcon color={color} />;

            case 'unauthorized-icon': return <UnauthorizedIcon color={color} />;

            default: return <SuspiciousIcon />;
        }
    }
    return (
        <>
            {
                //total_issue === 0 ? "" :
                <div className='middle-il-widget-container'>
                    {/* left side  */}
                    <div className="chart-section">
                        <div className="chart_draw" style={{ marginLeft: 0 }}>
                            <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} />
                        </div>
                        <div className="chart_info">
                            {
                                (total_issue !== undefined && total_issue >= 0) && <div className="chart_details">
                                    <div className="chart_total" style={{ cursor: 'default' }}>
                                        <p className="total_asset_label" title={convertToCommaValue(total_asset?.toString() || 0)}>{getRoundedUnitWithoutDot(total_asset)}</p>
                                        <span className="total-chart-summary-section">
                                            <span className="chart-total-label">
                                                <span>Issues</span>
                                                <span className='chart-total-incident-link' title={`${convertToCommaValue(getTotalIncidentCount())} total incidents \n${convertToCommaValue(getLast7DaysIncidentCount()) + ' incidents in last 7 days'}`} onClick={() => { handleIncidentCountClick(undefined) }}>
                                                    &nbsp;| {getRoundedUnitWithoutDot(getTotalIncidentCount()) + ' incidents'}
                                                </span>
                                            </span>
                                            {renderChartTrend(totalShadowTrend?.totalLast_7days_created, totalShadowTrend?.totalLast_7days_closed, totalTrendClickHandler)}
                                        </span>
                                        {/* <span
                                        style={{
                                            color: '#ff0066', cursor: 'pointer',
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                            maxWidth: '75px'
                                        }}
                                    >
                                        &nbsp;| 1.5K incidents
                                    </span> */}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>


                    {/* right side  */}
                    <div className="chart-summary-section">
                        <div className="chart-summary-content">
                            {total_issue! > 0 ? (
                                <section className="chart-summary-ul">
                                    {Object.keys(ruleListProp || {}).length > 0 &&
                                        Object.keys(ruleListProp || {})
                                            .sort((a, b) => (ruleListProp[a]?.id_count < ruleListProp[b]?.id_count ? 1 : -1))
                                            .map((p, index) => {
                                                const issueName = p || "";
                                                const percentage = ruleListProp[p]?.id_count || 0;
                                                const total = ruleListProp[p]?.incident_count || 0;
                                                const last_7days_closed_id = ruleListProp[p]?.last_7days_closed_id || 0;
                                                const last_7days_created_id = ruleListProp[p]?.last_7days_created_id || 0;
                                                const entityType = ruleListProp[p]?.entity_type || ''
                                                const countries = ruleListProp[p]?.countries || []
                                                const renderShadowActivityItem =
                                                    total > 0 ? (
                                                        <div title={isIssueType(selectedData, issueName) ? issueName : findNameById(groupData, issueName)} className="header issue-header" id={`scroll-${widgetId}-${issueName}`} onMouseOver={throttledMouseOverIssueHandler.bind(null, issueName)}>
                                                            <div className="issue-detail-item-container" style={{ display: "flex" }}>
                                                                {/* <span className="sh-left-icon" style={getColor(issueName)}>
                                                                    &#9632; &nbsp;
                                                                </span> */}
                                                                <span title={getIssueTypeTitle(issueName)} className={`type-of`}>{renderIcon(getIssueTypeIconClass(issueName), getColor(issueName))}</span>
                                                                <div className="issue-header-container" style={{ flexGrow: "1" }}>
                                                                    <div className="issue-name-heading" title={isIssueType(selectedData, issueName) ? issueName : findNameById(groupData, issueName)}>
                                                                        <span>{isIssueType(selectedData, issueName) ? getCustomLabelForRuleName(issueName) : findNameById(groupData, issueName)}</span>
                                                                    </div>
                                                                    <IncidentAssetRow total={total} entityType={entityType} percentage={percentage} issueName={issueName} issueDBName={p} incidentCount={ruleListProp[p]?.incident_count} last_7days_created_incident={ruleListProp[p]?.last_7days_created_incident} idCountClick={handleClick} incidentCountClick={handleIncidentCountClick} countries={countries} />
                                                                </div>
                                                                <div className='shadow_trend'>
                                                                    {
                                                                        renderChartTrend(last_7days_created_id, last_7days_closed_id, () => trendClickHandler(issueName))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : null;
                                                return renderShadowActivityItem;
                                            })}
                                </section>
                            ) : (
                                <section className="donut-no-data-template">
                                    <aside>No incidents or issues</aside>
                                </section>
                            )}
                        </div>
                    </div>

                </div>
            }
        </>
    );
};

const getTitleOnEntityType = (eType: string = '') => {
    const entity = eType.trim().toLowerCase();

    switch (entity) {
        case 'identity': return 'identities';
        case 'asset': return 'assets';
        case 'directory': return 'directories';
        case 'country': return 'countries';
        default: return 'assets';
    }
}

const getTitleOnEntityTypeAndCount = (eType: string = '', count: number) => {
    const entity = eType.trim().toLowerCase();

    switch (entity) {
        case 'identity': return count > 1 ? 'identities' : 'identity';
        case 'asset': return count > 1 ? 'assets' : 'asset';
        case 'directory': return count > 1 ? 'directories' : 'directory';
        case 'country': return count > 1 ? 'countries' : 'country';
        default: return 'assets';
    }
}

const IncidentAssetRow = ({ percentage, entityType, issueName, total, issueDBName, incidentCount, last_7days_created_incident, idCountClick, incidentCountClick, countries }: any) => {
    return (
        <article>
            {/* <span className={`type-of ${getIssueTypeIconClass(issueName)}`}>{' '}</span> */}
            <span className="trimmed-container">
                {countries && countries.length > 0 ?
                    <Tooltip
                        classes={{ tooltip: 'posture-root-column-container scrollbar-container' }}
                        title={<div className="service_list2">
                            {countries.length > 0 ?
                                <table>
                                    <tr>
                                        <th className="align_left">Countries</th>
                                    </tr>
                                    {countries
                                        .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
                                        .map((item: any, index: any) => {
                                            return (<tr key={item['name'] + '-' + index}>
                                                <td className="hostname_td align_left display-flex-column">

                                                    <div style={{ display: 'flex' }}>
                                                        {item['code'] ?
                                                            <span className="flag_wrapper">
                                                                {item['name'] ?
                                                                    <div className="flag_icon" style={{ position: 'relative' }}>
                                                                        <AMCountryFlag countryCode={item['code']} />
                                                                    </div>
                                                                    :
                                                                    null}
                                                            </span>
                                                            :
                                                            <span className="flag_wrapper">
                                                                {item['name'] ?
                                                                    <div className="location_icon">
                                                                    </div>
                                                                    :
                                                                    <div className="flag_icon" style={{ position: 'relative' }}>
                                                                        <div className='no_flag'></div>
                                                                    </div>}
                                                            </span>
                                                        }
                                                        {item['name']}
                                                    </div>
                                                </td>
                                            </tr>);
                                        })}
                                </table>
                                : null
                            }
                        </div>}
                    >
                        <span onClick={() => idCountClick(issueName, entityType)} title={percentage ? convertToCommaValue(percentage) : "0"}>
                            <span className="asset-count">
                                {percentage !== "NaN" ? (percentage.toString().length < 3 ? convertToCommaValue(percentage) : getRoundedUnitWithoutDot(percentage)) : "0"}
                            </span>
                            <span className="asset-type">&nbsp;{getTitleOnEntityTypeAndCount(entityType, percentage)}</span>
                        </span>
                    </Tooltip>
                    : <span onClick={() => idCountClick(issueName, entityType)} title={percentage ? convertToCommaValue(percentage) : "0"}>
                        <span className="asset-count">
                            {percentage !== "NaN" ? (percentage.toString().length < 3 ? convertToCommaValue(percentage) : getRoundedUnitWithoutDot(percentage)) : "0"}
                        </span>
                        <span className="asset-type">&nbsp;{getTitleOnEntityTypeAndCount(entityType, percentage)}</span>
                    </span>
                }
                <span
                    title={`${convertToCommaValue(incidentCount || 0)} total incidents \n ${convertToCommaValue(last_7days_created_incident || 0) + " incidents in last 7 days"}`}
                    style={{ color: "#ff0066", cursor: "pointer" }}
                    onClick={() => {
                        incidentCountClick({ name: issueName });
                    }}
                >
                    &nbsp;| {total ? (total.toString().length < 3 ? convertToCommaValue(total) + `${total === 1 ? ' incident' : ' incidents'}` : getRoundedUnitWithoutDot(total) + " incidents") : "0 incident"}
                </span>
            </span>
        </article>
    );
};

export default React.memo(DonutChart);
