import InfoIcon from '@mui/icons-material/Info'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import React, { memo, SyntheticEvent, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import 'react-sliding-side-panel/lib/index.css'
import {
    ArrayParam,
    NumberParam,
    StringParam,
    withDefault,
    withQueryParams,
} from 'use-query-params'
import { Api } from '../../components/Axios'
import { useToasts } from '../../components/core'
import './Settings.scss'
import { AMToolTipAd } from '../../components/core/AMTooltipAd/AMTooltipAd'
import { AMModalError } from '../../components/core/AMModalPopup/AMModalError'
import { SettingsErrors } from './SettingsErrors'
import { useLocation } from 'react-router-dom';
import qs from 'qs'
import AMTabs, { TabData } from '../../components/core/AMTab/AMTab'

type Search = {
    query?: string;
}

type PostureSettings = {
    data_archive_time: number,
    posture_view_time: number
}

type SensorSettings = {
    third_party_calls_via_am?: boolean;
}

type GeneralSetting = {
    sensors?: SensorSettings,
    posture?: PostureSettings
}

type GeneralSettings = {
    tab_view: number,
    general_settings: GeneralSetting
}

const PostureSettingsComp = memo(({ sensorSettings, register, errors, handleSubmit, handlePostureSettingSubmt, setSensorSettings, setValue, loading }: any) => {
    return (
        <div className=''>
            <form className='posture_setting_form_container' onSubmit={handleSubmit(handlePostureSettingSubmt)}>
                <div className='posture_flex_container'>
                    <div className='flex-div-item-'>
                        <span>
                            Archive incident and posture data after {sensorSettings.general_settings.posture?.data_archive_time}
                        </span> days of inactivity
                    </div>
                </div>
                <div className='posture_flex_container'>
                    <div className='flex-div-item-'><span>
                        Do <strong><i>not</i></strong> show posture data if there is no activity for <span>
                            <input type='number'
                                defaultValue={sensorSettings?.general_settings?.posture?.posture_view_time}
                                name='general_settings.posture.posture_view_time'
                                ref={register({
                                    required: true,
                                    min: 15,
                                    max: 60
                                })}
                                onChange={(e) => {
                                    const ob = { ...sensorSettings };
                                    if (ob?.general_settings?.hasOwnProperty('posture')) {
                                        ob.general_settings.posture.posture_view_time = parseInt(e?.target?.value) || 0;
                                        setSensorSettings(ob);
                                    }
                                }}
                            />
                        </span>
                    </span> days
                    </div>
                </div>
                <AMModalError errors={errors?.general_settings?.posture || {}} errorMap={SettingsErrors.posture} />
                <div className="launch_button no_float">
                    <button type={(loading ? 'button' : 'submit')} className={"float_right " + (loading ? 'loader' : 'button_styled')}>Save</button>
                </div>
            </form>
        </div>
    );
});


const Groups = ({ query, setQuery }: any) => {
    const tabs: TabData[] = [
        {
            label: "Posture Archive Settings".toLocaleUpperCase(),
            Component: <PostureSettingsComp></PostureSettingsComp>,
            route: "/general_settings",
            selected: true,
            title: "Posture Archive Settings"
        }
    ];

    const [tabValue, setTabValue] = React.useState('2');
    const [loading, setLoading] = useState<Boolean>(false)
    const { register, handleSubmit, errors, setValue, watch } = useForm<GeneralSettings>({ mode: 'onChange' })
    const [sensorSettings, setSensorSettings] = useState<GeneralSettings>({ general_settings: {}, tab_view: 1 });
    const { addToast } = useToasts();
    const location = useLocation();

    const values = watch();
    useEffect(() => {
        Listing()
        const query = new URLSearchParams(location.search);
        if (query && query.get('type') == 'posture') {
            setTabValue('2');
            handleTabs({} as SyntheticEvent, '2')
        }
    }, [])

    useEffect(() => {
        console.log(tabValue)
    }, [tabValue])

    const Listing = () => {
        Api.get('/generalsettings')
            .then((res: { data: any }) => {
                const responseData = res.data as GeneralSettings;
                if (res.data) {
                    setValue('general_settings.posture.posture_view_time', responseData?.general_settings?.posture?.posture_view_time || 60);
                    setValue('general_settings.posture.data_archive_time', responseData?.general_settings?.posture?.data_archive_time);
                    setValue('general_settings.sensor.third_party_calls_via_am', responseData.general_settings.sensors?.third_party_calls_via_am)
                }
                setSensorSettings(res.data);
                // 
            })
            .catch((error: any) => {
            })
    }

    const onSetSensors = (data: GeneralSettings) => {
        setLoading(true)
        let request = {
            general_settings: { sensors: data.general_settings.sensors }
        } as GeneralSettings;
        const headers = { 'Operation': 'PUT' }
        processRequest(request, headers, 1);
    }

    const processRequest = (request: GeneralSettings, headers: any, tabView: number) => {
        const req = tabView == 1 ? Api.post('/generalsettings', request, { headers: headers }) :
            Api.post('/generalsettings/posture', request, { headers: headers })

        req.then((res: { data: any }) => {
            setLoading(false)
            addToast("Settings Updated successfully.", {
                appearance: 'success',
                autoDismiss: true,
            })
        })
            .catch((error: any) => {
                setLoading(false)
                if (error.response.status === 500) {
                    addToast("Sorry, something went wrong there, try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 419) {
                    addToast("We encounted validation problem, please correct and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                } else if (error.response.status === 404) {
                    addToast("We are not able to proccess the request, please check and try again.", {
                        appearance: 'error',
                        autoDismiss: true,
                    })
                }
            })
    }

    const handleTabs = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
        if (newValue == '2') {
            setTimeout(() => {
                setValue('general_settings.posture.posture_view_time', sensorSettings?.general_settings?.posture?.posture_view_time || 60);
                setValue('general_settings.posture.data_archive_time', sensorSettings?.general_settings?.posture?.data_archive_time);
            });
        } else {
            setTimeout(() => {
                setValue('general_settings.sensor.third_party_calls_via_am', sensorSettings.general_settings.sensors?.third_party_calls_via_am)
            })
        }

    };

    const handlePostureSettingSubmt = (data: GeneralSettings) => {
        setLoading(true)
        let request = {
            general_settings: {
                posture: {
                    // data_archive_time: parseInt(data?.general_settings?.posture?.data_archive_time?.toString() || '0'),
                    data_archive_time: sensorSettings.general_settings.posture?.data_archive_time,
                    posture_view_time: parseInt(data?.general_settings?.posture?.posture_view_time?.toString() || '0')
                }
            }
        } as GeneralSettings;
        const headers = { 'Operation': 'PUT' }
        processRequest(request, headers, 2)
    }

    useEffect(() => {
        console.log(errors?.general_settings)
        console.log(values)
    }, [errors, values])



    return (
        <>
            <div className="page_title_area">
                <h2>General Settings</h2>
            </div>
            <div className="page_title_area">
                <div className="box_grid_layout general_settings">
                    <div className='font14'><strong>Posture Archive Settings</strong></div>
                    <div className="clrBoth margintop20"></div>
                    <PostureSettingsComp
                        sensorSettings={sensorSettings}
                        register={register}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        handlePostureSettingSubmt={handlePostureSettingSubmt}
                        setSensorSettings={setSensorSettings}
                        setValue={setValue}
                        loading={loading}
                    />
                </div>
                {/*   <AMTabs tabs={tabs}
                    isPopupBased={true}
                    onTabClick={(val) => {
                        setTabValue(val)
                        console.log(val)
                    }} /> */}
            </div >
        </>
    )
}

export default withQueryParams({
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, [])
}, Groups)