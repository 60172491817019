import React, { useEffect, useRef, useState } from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import "./DayFlowsChart.css";
import moment from "moment";
import { addDaysToEpoc, addHoursToEpoc, addMinsToEpoc, getResolutionMatch } from "../../../utils/util-methods";
type Props = {
  flowsData:
        | any
        | undefined;
        primaryColor: String | undefined;
        showNavigator?: boolean | undefined;
        test?:Number;
        noData?: boolean;
        maxY?: number;
        showXAxisLabels?: boolean;
        noDataMessage?:string|undefined;
        zoomLevel?:string;
};

function getElementByClass(selector: string) {
    let elem = document.getElementsByClassName(selector);
    return elem?.length > 0 ? elem[0] : null;
}

const DaysFlowChart = ({ flowsData, primaryColor = '#9fa1ae', showNavigator=true , test=-1,noData = false, maxY=60,showXAxisLabels=false,noDataMessage='' , zoomLevel}: Props) => {
    const [chartOptions90Days, setChartOptions90Days] = useState<any>(null);
    const chartRef = useRef<{
        chart: Highcharts.Chart;
        container: React.RefObject<HTMLDivElement>;
    }>(null);

    useEffect(() => {

        setChartOptions90Days({
            chart: {
                type: "spline",
                renderTo: "chart",
                style: {
                    fontFamily: "Metropolis-Regular"
                },
                width: getResolutionMatch(1800, 900),
                height:getResolutionMatch(150, 98),
                events: {
                    load: () => {

                    }
                },
            },
            credits: undefined,
            title: {
                align: "left",
                useHTML: true,
                text: ""
            },
            subtitle: {
                text: ""
            },
            legend: {
                enabled: false
            },
            tooltip: {
                shape: "rect",
                // hideDelay: 50000,
                positioner: function (w, h, p) {
                    return {
                        x: 54+ Math.min(getResolutionMatch(1400,705), Math.max(getResolutionMatch(290,110), p.plotX)),
                        y: p.plotY - 10
                    };
                },
                useHTML: true,
                padding: 10,
                shadow: false,
                borderWidth: 0,
                backgroundColor: "#ffffff00",
                formatter: function () {
                const chrt = chartRef.current?.chart;
                let { userMax, userMin } = chrt?.xAxis[0].getExtremes() || { userMax: 0, userMin: 0 };

                const hoursDiff = moment(userMax).diff(userMin, 'hours');

                // Default time format
                let timeFormat = "MMM DD YYYY, hh:mm A";

                // Adjust time format based on zoom level
                if (zoomLevel === '1-minute' || zoomLevel === '10-minute') {
                    timeFormat = "hh:mm A";
                }

                // Combine base date (test) and this.x (time)
                const baseDate = moment(test);  // test is your base date
                const timeFromX = moment(this.x, "hh:mm A");  // Assuming this.x contains time in "hh:mm A" format

                // Add the time from this.x to the base date
                const combinedDateTime = baseDate.clone().set({
                    hour: timeFromX.get('hour'),
                    minute: timeFromX.get('minute')
                });

                // Calculate start time based on the combined datetime
                const startTime = combinedDateTime.format(timeFormat);

                // Calculate end time based on zoom level and time difference
                let endTime;
                if (hoursDiff < 12 && zoomLevel === '1-minute') {
                    endTime = combinedDateTime.add(1, 'minute').format(timeFormat);
                } else if (hoursDiff < 48 && zoomLevel === '10-minute') {
                    endTime = combinedDateTime.add(10, 'minutes').format(timeFormat);
                } else {
                    endTime = combinedDateTime.add(1, 'hour').format(timeFormat);
                }

                // Return the tooltip HTML
                return `<span class='flow-chart-tooltip' style='box-shadow: 2px 2px 4px -2px ${primaryColor}; border: 1px solid ${primaryColor};'>
                            <p>${startTime} - ${endTime}</p><br>
                            <b>Flows: ${parseFloat(this.y).toFixed()}</b>
                        </span>`;
                    
                    
                    
        },
                style: {
                    zIndex: 100000
                }
            },
            plotOptions: {
                line: {
                    color: primaryColor,
                    lineWidth: getResolutionMatch(1.5, 0.75),
                    states: {
                        hover: {
                            lineWidth: getResolutionMatch(3, 1.5),
                            fillColor: primaryColor,
                        }
                    }
                },
                series: {
                    stacking: 'normal',
                    pointPadding: 0,
                    groupPadding: 0,
                    borderWidth: 0,
                    shadow: false,
                    dataGrouping: {
                        enabled: false
                    },
                    marker: {
                        radius: getResolutionMatch(5, 1.5),
                        states: {
                            hover: {
                                enabled: true,
                                fillColor: primaryColor,
                            },
                        }
                    },
                    size: 240,
                    innerSize: '100%',
                    center: [80, '50%']
                }
            },
            xAxis: {
                crosshair: true,
                minPadding: 0.05,
                maxPadding: 0.05,
                categories: flowsData.map(p => moment(p[0]).format('hh:mm A')),
                labels: {
                    enabled: showXAxisLabels,
                    padding: 2,
                    step: 5, // Adjust step based on the number of points // Rotate if there are many points
                    overflow:'justify',
                    style:{
                        fontSize:getResolutionMatch(21,12)
                    }
                },
                tickInterval: flowsData.length > 100 ? Math.floor(flowsData.length / 65) :''
            },
            yAxis: {
                title: {
                    text: ''
                },
                labels: {
                    style:{
                        fontSize:getResolutionMatch(22,12)
                    },
                }
            },
            plotOptions: {
                spline: {
                     color: primaryColor,
                    //color: "#fdbf06",
                    lineWidth: getResolutionMatch( 3,1.5),
                    states: {
                        hover: {
                            lineWidth: getResolutionMatch( 3,1.5)
                        }
                    }
                },
                series: {
                    dataGrouping: {
                        enabled:false
                    }, 
                    marker: {
                        enabled: false,
                        radius: getResolutionMatch( 5,1.5),
                        states: {
                            hover: {
                                enabled: true
                            }
                        }
                    },
                }
            },
            series: [{
                data: flowsData.map(function(p) {
                    return {
                        y: p[1],
                        marker: {
                            //enabled: false,
                            enabled: p[1]>0 ? true : false,
                            radius: getResolutionMatch(3,1.5),
                            fillColor: '#FFFFFF', //getColor(p[1]),
                            lineColor: primaryColor, // inherit from series
                            lineWidth: getResolutionMatch(0.5, 0.25),
                        }
                    }
                }),
                // zones: [{
                //     value: 0,
                //     color: '#ffcc33'
                // }, {
                //     value: 500,
                //     color: '#fe7106'
                // }, {
                //     value:1000,
                //     color: '#fe4923'
                // },
                // {
                //     value:3000,
                //     color: '#fa1262'
                // },
                // {
                //     color: '#ff0066'
                // }
                // ]
            }]
        })

    }, []);
    
    const getColor = (value:number) => {
        if(value >=5000) return '#ff0066'
        if(value >=3000) return '#fa1262'
        if(value >=1000) return '#fe4923'
        if(value >=500) return '#fe7106'
        if(value > 0) return '#ffcc33'
    }

    return (
        <>
            <div className="trend-day" style={(noDataMessage==='N/A')?{opacity:0.2}:{opacity:1}}>
                {(noDataMessage === 'No data available') && <div className="no-data-available">{noDataMessage}</div>}
                {(noDataMessage === 'N/A') && <div className="future-dates">{noDataMessage}</div>}
                <span className="flows-yaxis-title">{moment(test).format('dddd')}</span>
                 {/* <span className="flows-yaxis-title-v">{moment(test).format('dddd')}</span> */}
                <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions90Days} />
            </div>
        </>
    );
};

export default React.memo(DaysFlowChart);
