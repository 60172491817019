import React from 'react';
import { GlobalConsts } from '../../../GlobalConst';

export const SyslogCollect = ({
    collectorName,
    collectionMethodData
}) => {

    const deploymentGuides = [{
         url: `${GlobalConsts.DEPLOYMENT_LINK}/bastion-host-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Bastion SSH"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/draft-blue-coat-proxysg?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Blue Coat ProxySG"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/cisco-adaptive-security-appliance-asa-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Cisco ASA"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/cisco-secure-firepower-threat-defense-ftd-collecto?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Cisco Firepower Threat Defense (FTD)"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/check-point-firewall-vpn-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Check Point Firewall & VPN"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/f5-vpn-collectorr?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "F5 Big-IP APM & VPN"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/fortisase-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Fortinet FortiSASE"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/fortinet-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Fortinet Firewall & VPN"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/open-vpn-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "OpenVPN"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/palo-alto-networks-next-generation-firewall-collec?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Palo Alto Firewall & VPN"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/sonicwall-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "SonicWall Firewall & VPN"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/zscaler-logs-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Zscaler ZIA (Web)"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/zscaler-logs-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Zscaler ZIA (DNS)"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/zscaler-logs-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Zscaler ZPA"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/zscaler-logs-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Zscaler Firewall"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/draft-cisco-meraki-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Cisco Meraki"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/vmware-vsphere-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "VMware vSphere"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/netflow-logs-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Generic Netflow & IPFIX Traffic"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/sflow-logs-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Generic Sflow Traffic"
      },
      {
         url: `${GlobalConsts.DEPLOYMENT_LINK}/enterprise-control-center-collector?${GlobalConsts.DOC_TOKEN}`,
        "collector_data_source": "Evernorth Control Center"
      }]

    return (
        <div className='collector-configuration-vendor-container'>
            <div className={`form_sub_section`}>
            <div className='form_sub_section_header'>
                        {collectorName} Configuration:
                    </div>
                <div className='form_sub_section_content'>
                    <div className='sensor-flex-container'>
                        <div className='sensor-flex-container-item'>
                            <div className='syslog-viewtext'>Configure {collectorName} {!['Generic Netflow & IPFIX Traffic','Generic Sflow Traffic','VMware vSphere'].includes(collectorName) ?<span>to syslog</span> : ''} to {collectionMethodData?.flow_sensor_name} ({collectionMethodData?.flow_sensor_ip}) on  {['Cisco ASA','Cisco Firepower Threat Defense (FTD)','Check Point Firewall & VPN','F5 Big-IP APM & VPN','Fortinet FortiSASE','SonicWall Firewall & VPN','Cisco Meraki' ,'Generic Netflow & IPFIX Traffic','Generic Sflow Traffic','VMware vSphere'].includes(collectorName) ? 'UDP Port':'TCP Port'}  { collectionMethodData?.flow_sensor_port} 
                                <a className="link-text" href={deploymentGuides.find(item => item.collector_data_source === collectorName)?.url} target="_blank" style={{ paddingLeft: "5px"}}>Learn More</a> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
