import React, { useState, useEffect } from "react"
import { findNameById, getCustomLabelForRuleName, getRiskLevelColor, getRoundedUnitWithoutDot, isIssueType } from "../../../../utils/util-methods"
import { BasePostureActionMenu } from "../PostureActionMenus/BasePostureActionMenu"
import './ScrollIssue.css'
import { BasePostureObj, RulesDetailObj } from "../../../../types/response-types"
import { Tooltip } from '@mui/material';
import { RuleType } from "../../../playbooks/constants/Constants"
import { getIssueNameById } from "../../../playbooks/helpers/playbook-helper"

const _4K = window.matchMedia("(min-width: 2560px)").matches;

type ScrollIssueProps = {
    data: Record<string, RulesDetailObj>,
    handleRuleFilterClick(e: React.MouseEvent<HTMLDivElement>): any,
    loadBasePostureActionProps(item: any, rule_name: string, issue_count: number): any,
    issueList: Array<string>,
    postureObject: BasePostureObj,
    classes?: {
        containerClass?: string,
        tableClass?: string
    },
    selectedItems: any;
    isAdditionalResultColVisible: any;
    tableWidth: string;
    isTimeBasedSearchApplied?: boolean
}

type ScrollIssueHeaderProps = {
    issueList: Array<string>,
    title: string,
    query: any,
    handleSort?(e: React.MouseEvent): any,
    classes?: {
        tableClass?: string
    }
    selectedItems: any;
    isAdditionalResultColVisible: any;
    tableWidth: string;
}

export interface ScrollIssueHeaderRef {
    onScrollToViewByRule(rule_name: string): any,
    reAlignTableData(): any
}

export const ScrollIssueHeader = React.forwardRef(({ issueList, title, query, handleSort, classes ,selectedItems, isAdditionalResultColVisible, tableWidth }: ScrollIssueHeaderProps, ref: any) => {
    const [clickCount, setClickCount] = useState(0);
    const [ruleName, setRuleName] = useState('');
    React.useImperativeHandle(ref, () => ({
        onScrollToViewByRule(rule_name: any) {
            return onScrollToViewByRule(rule_name);
        },
        reAlignTableData() {
            return reAlignTableData();
        }
    } as ScrollIssueHeaderRef));

    const [selectedColumn, setSelectedColumn] = React.useState(query?.sort_by || '');
    const [currentSort, setCurrentSort] = React.useState(query?.order_by == 'asc' ?
        ' tablesort_up tablesort_up_selected ' : ' tablesort_down tablesort_down_selected ');

    const highlightSortDir = () => {
        if (issueList.includes(query?.sort_by || "")) {
            if (query?.order_by === "asc") {
                setCurrentSort(" tablesort_up tablesort_up_selected ")
            } else if (query?.order_by === "desc") {
                setCurrentSort(" tablesort_down tablesort_down_selected ")
            }
        } else {
            setCurrentSort("tablesort_down");
            setSelectedColumn("");
        }
    }

    const  findNameById = ( idToFind: string): string  =>{
        for (const obj in selectedItems) {
                if(selectedItems.hasOwnProperty(obj)){
                        const foundItem = selectedItems[obj].playbooks.find((item:any) => item._id === idToFind);
                        if (foundItem) {
                            return foundItem.name;
                        }
                }
        }
        return idToFind; 
      }

      

    const setRuleNameTag = () => {
        const q = query?.q;
        if (q) {
            const queries = q?.split('+') || [];
            if (queries?.length > 0) {
                const issues = queries.find((i: string) => i.includes('rule_name'));
                if (issues) {
                    const issuesList = issues?.substring(issues.indexOf(':') + 1)?.split(',') || '';
                    if (issuesList?.length == 1) {
                        setRuleName(issuesList[0]);
                    }
                }
            }
        }
    }

    React.useEffect(() => {
        highlightSortDir();
        setRuleNameTag();
    }, [query])


    /*  useEffect(() => {
         setClickCount(0);
         resetHeaders();
         onScrollToViewByRule(ruleName);
     }, [ruleName]) */

    const onHeaderClick = (e: React.MouseEvent) => {
        const col = e.currentTarget.getAttribute("data-headerName");
        if (col && e && handleSort) {
            handleSort(e);
            setSelectedColumn(col);
        }
    }

    const onHeaderScrollClick = (side: string) => {
        if (side === 'left') {
            const array = Array.from(document.querySelectorAll('.scroll-issue-table tr.scroll-click'));
            array.forEach((element: any) => {
                element.scrollLeft -= (_4K ? 100 : 100);
                document.getElementsByClassName('right-arrow')[0]?.classList.remove('right-arrow-disabled');
                if (element.scrollLeft === 0) document.getElementsByClassName('left-arrow')[0]?.classList.add('left-arrow-disabled');

            })
            setClickCount(clickCount - 1);
        } else if (side === 'right') {
            const array = Array.from(document.querySelectorAll('.scroll-issue-table tr.scroll-click'));
            array.forEach((element: any) => {
                element.scrollLeft += (_4K ? 100 : 100);
                document.getElementsByClassName('left-arrow')[0]?.classList.remove('left-arrow-disabled');
                if (Math.round(element.scrollLeft) >= Math.round(element.scrollWidth - element.offsetWidth)) {
                    document.getElementsByClassName('right-arrow')[0]?.classList.add('right-arrow-disabled')
                }
            })
            setClickCount(clickCount + 1);
        }
    }

    const reAlignTableData = () => {
        for (let i = 0; i < clickCount; i++) {
            const array = Array.from(document.querySelectorAll('.scroll-issue-table.inner-table-data tr.scroll-click'));
            array.forEach((element: any) => {
                element.scrollLeft += (_4K ? 100 : 100);
            })
        }
    }

    const resetHeaders = () => {
        const a = document.getElementsByClassName('inner-table-header')[0];
        const left = a?.getBoundingClientRect().left || 0;
        const el = document.querySelectorAll('[data-breadcrumb]')[0] as HTMLElement;
        const dataset = el?.dataset as DOMStringMap;
        const rule_name = dataset?.breadcrumb?.toString();
        if (rule_name) {
            while (left > (document.getElementById(rule_name)?.getBoundingClientRect()?.left || 0)) {
                onHeaderScrollClick('left');
            }
        }
    }

    const onScrollToViewByRule = (rule_name: string) => {
        setClickCount(0);
        resetHeaders();
        const a = document.getElementsByClassName('inner-table-header')[0];
        const right = a?.getBoundingClientRect().right || 0;
        while (right < (document.getElementById(rule_name)?.getBoundingClientRect()?.right || 0)) {
            onHeaderScrollClick('right');
        }
    }

    return <table className={'scroll-issue-table inner-table-header ' + classes?.tableClass} style={isAdditionalResultColVisible ? {maxWidth: tableWidth} : {}}>
        <tr style={{ display: "flex", height: '100%' }}><th className='line_ht30' colSpan={1} style={{ width: "100%", borderRight: "none" }}>
            {
                issueList?.length > 8 && title !== 'Issues' &&
                <div className='left-arrow left-arrow-disabled' onClick={() => onHeaderScrollClick('left')}></div>}
                {
                issueList?.length > 4 && title === 'Issues' &&
                <div className='left-arrow left-arrow-disabled' onClick={() => onHeaderScrollClick('left')}></div>}
            <div>{title}</div>

            {
                issueList?.length > 8 && title !== 'Issues' &&
                <div className='right-arrow' onClick={() => onHeaderScrollClick('right')}></div>}
                {
                issueList?.length > 4 && title === 'Issues' &&
                <div className='right-arrow' onClick={() => onHeaderScrollClick('right')}></div>}
        </th></tr>
        <tr className="th_ver_height scroll-click tr-height" style={{ background: '#d4d8e1' }}>
            {
                issueList?.map((i: string) => {
                    let rule_name = getCustomLabelForRuleName(i);
                        rule_name = selectedItems  ? findNameById(rule_name) : rule_name;

                     
                    return <Tooltip title={i === 'Suspected Attack on Expired AD Account' ? 'Suspected Attack on Expired AD Account/Password' :
                        isIssueType(selectedItems, i) ? getIssueNameById(i) : getIssueNameById(rule_name)}
                        placement="top"
                        classes={{
                            tooltip: 'scroll-issue-header-tooltip'
                        }}
                    >

                        <th
                            onClick={onHeaderClick}
                            data-headerName={i}
                            data-breadcrumb={i}
                            id={i}
                            style={{
                                width: window.matchMedia("(min-width: 2560px)").matches ? 52 : 52
                            }}
                            className={"vertical_th tablesort_down" + (selectedColumn == i ? currentSort : " tablesort_down")}
                        >
                            <div className='tb_hr_header'>
                                <span>
                                    {
                                        <>   {
                                            rule_name.split(' ')[0]?.length + rule_name.split(' ')[1]?.length < 20 && rule_name.split(' ')[2] ? <>
                                                {rule_name.split(' ')[0] + ' ' + rule_name.split(' ')[1]}<br></br>
                                                {rule_name.split(' ')[2]}
                                            </> : <>
                                                {rule_name.split(' ')[0]} {rule_name.split(' ')[1]?.length < 3 ? <>{rule_name.split(' ')[1]}</> : <></>}<br></br>
                                                {rule_name.split(' ')[1]?.length < 3 ? <></> : <>{rule_name.split(' ')[1]}<br></br></>}
                                                {rule_name.split(' ')[2]?.length < 6 ? rule_name.split(' ')[2] : ''}
                                            </>
                                        }
                                        </>
                                    }
                                </span>
                            </div></th>
                    </Tooltip>
                })
            }
        </tr>
    </table >
});

export const ScrollIssueData = ({ data, handleRuleFilterClick, loadBasePostureActionProps, issueList, postureObject, classes, selectedItems, isAdditionalResultColVisible, tableWidth, isTimeBasedSearchApplied }: ScrollIssueProps) => {
    const hasNonZeroIssueCount = (playbooks:any) => {
        return playbooks?.some((playbook:any) => {
            const issueCount = data[playbook._id]?.issue_count || 0; // Treat undefined issue count as 0
            return issueCount !== 0;
        });
    };
    
    const renderPlaybooks = (playbooks: any , issueName: string , id: string) => {
        let sortedPlaybooks = playbooks?.slice().sort((a, b) => {
            return data[b._id]?.issue_count - data[a._id]?.issue_count;
        });
        sortedPlaybooks = sortedPlaybooks?.slice().sort((a, b) => {
            return data[b._id]?.issue_risk - data[a._id]?.issue_risk;
        });
    
        const topPlaybooks = sortedPlaybooks.slice(0, 4); 
        const otherPlaybooks = sortedPlaybooks.slice(4); 
    
        let otherIssueCount = 0;
        otherPlaybooks.forEach((playbook: any) => {
            otherIssueCount += data[playbook._id]?.issue_count || 0; 
        });
    
        return (
            <>
                {topPlaybooks.map((playbook: any) => (
                    data[playbook._id]?.issue_count && (
                        <div key={playbook._id} style={{overflow:'hidden',width:'100%', borderTop:'1px solid #aaa',}}>
                            <div className='dot_rule_count' style={{ backgroundColor: getRiskLevelColor(data[playbook._id]?.issue_risk) , padding:'5px 8px'  , borderRadius:0, width:'30px', cursor:'pointer'}} id={id}   data-rulename={playbook._id}
                                onClick={data[playbook._id]?.issue_count === 0 ? () => { } : handleRuleFilterClick}>
                                <b>{ getRoundedUnitWithoutDot(data[playbook._id]?.issue_count)}</b> 
                            </div>
                            <div className='dot_rule_text' title={findNameById(selectedItems, playbook._id)} style={{padding:'5px 8px', cursor:'pointer', width: '70%',textOverflow: 'ellipsis',overflow: 'hidden',whiteSpace: 'nowrap',textAlign:'left'}} id={id}   data-rulename={playbook._id}
                                onClick={data[playbook._id]?.issue_count === 0 ? () => { } : handleRuleFilterClick} > {findNameById(selectedItems, playbook._id) } </div> 
                        </div>
                    )
                ))}
                {otherPlaybooks.length > 0 && (
                    <div key="other" style={{overflow:'hidden',width:'100%', borderTop:'1px solid #aaa'}}>
                        <div className='dot_rule_count' style={{backgroundColor:'#aaaaaa',color:'#555555' , padding:'5px 8px'  , borderRadius:0, width:'30px', cursor:'pointer'}} id={id}   data-rulename={'other'}
                            >
                            <b>{getRoundedUnitWithoutDot(otherIssueCount)}</b> 
                        </div>
                        <div className='dot_rule_text' title="Other" style={{padding:'5px 8px', cursor:'pointer', width: '70%',textOverflow: 'ellipsis',overflow: 'hidden',whiteSpace: 'nowrap',textAlign:'left'}} id={id}   data-rulename={'other'}
                             > Other </div> 
                    </div>
                )}
            </>
        );
    };
    
      

    return <table className={'scroll-issue-table inner-table-data ' + classes?.tableClass} style={isAdditionalResultColVisible  ? {maxWidth: tableWidth} : {}} >
        <tr className='dot_outer scroll-click'>
            {

                issueList.map((issue: string, index: number) => {
                    const item = data[issue];
                    return item ? <td style={{
                        // maxWidth: window.matchMedia("(min-width: 2560px)").matches ? 102 : 51,
                        // minWidth: window.matchMedia("(min-width: 2560px)").matches ? 102 : 52,
                        width: window.matchMedia("(min-width: 2560px)").matches ? 52 : 52,
                        boxSizing: "border-box", borderBottom: "none"
                    }}
                        key={item.name + '-' + index}
                        className={item.issue_count === 0 ? "" : "posture-column-pointer"}
                        data-breadcrumb={issue}
                    >
                        <div className={"scroll-issue-action-column-container " + classes?.containerClass} >
                            <div className="posture-column-container">
                            
                            {item.issue_count === 0 ? (
                                        <span className='dot_rule_hide'>&bull;</span>
                                    ) : (
                                        <>
                                            {isIssueType(selectedItems, item.name) && hasNonZeroIssueCount (selectedItems[item.name]?.playbooks) ?  <Tooltip
                                                title={<div>
                                                     <div className='hover_data-scroll' style={{overflow:'hidden', height:'auto',minWidth:'250px'}}>
                                                     <div style={{textAlign:'center', padding:'5px',}}> <b style={{fontSize:'14px'}}>{item.issue_count}</b> Incidents </div> 
                                                       <div style={{overflow:'hidden',width:'100%', borderTop:'1px solid #aaa',}}>
                                                        <div style={{float:'left',width:'25px', borderRight:'1px solid #aaa', padding:'3px 10px', textAlign:'center'}}><b>#</b></div>
                                                        <div style={{float:'left', padding:'3px 10px'}}><b>Playbook Name</b></div>
                                                     </div>
                                                      {renderPlaybooks(selectedItems[item.name]?.playbooks, item?.name , postureObject?._id?.toString() )}                                                                                                         
                                                     </div>                                                     
                                                </div>  }
                                                classes={{
                                                    tooltip: 'transparent-bg'
                                                }}
                                            >
                                                <span
                                                    id={postureObject?._id?.toString()}
                                                    key={item.name + '-' + index + '-' + item.issue_risk}
                                                    data-rulename={item.name}
                                                    onClick={item.issue_count === 0 ? () => { } : handleRuleFilterClick}
                                                    className='dot_rule posture-column-dot shadowbox-rules' style={{ color: getRiskLevelColor(item.issue_risk), width: '60%' }}>
                                                    &bull;
                                                </span>
                                            </Tooltip> :  <Tooltip
                                                title={
                                                    <div className='hover_data-scroll'>
                                                        <div className='dot_rule_count' style={{ backgroundColor: getRiskLevelColor(item.issue_risk) }}>{item.issue_count}</div>
                                                        <div className='dot_rule_text'>Incidents</div>
                                                    </div>
                                                }
                                                classes={{
                                                    tooltip: 'transparent-bg'
                                                }}
                                            >
                                                <span
                                                    id={postureObject?._id?.toString()}
                                                    key={item.name + '-' + index + '-' + item.issue_risk}
                                                    data-rulename={item.name}
                                                    onClick={item.issue_count === 0 ? () => { } : handleRuleFilterClick}
                                                    className='dot_rule posture-column-dot shadowbox-rules' style={{ color: getRiskLevelColor(item.issue_risk), width: '60%' }}>
                                                    &bull;
                                                </span>
                                            </Tooltip> }
                                        </>
                                    )}

                                {item.issue_count > 0 && !isTimeBasedSearchApplied ?
                                    <BasePostureActionMenu {...loadBasePostureActionProps(postureObject, item.name, item.issue_count)}
                                    /> : null}
                            </div>
                        </div>
                    </td> :

                        <td
                            data-breadcrumb={issue}
                            style={{
                                // maxWidth: window.matchMedia("(min-width: 2560px)").matches ? 102 : 51,
                                // minWidth: window.matchMedia("(min-width: 2560px)").matches ? 102 : 52,
                                width: window.matchMedia("(min-width: 2560px)").matches ? 52 : 52,
                                boxSizing: "border-box", borderBottom: "none"
                            }}>
                            <div className={"scroll-issue-action-column-container " + classes?.containerClass} >
                                <div className="posture-column-container"></div>
                            </div>
                        </td>

                })

            }
        </tr>
    </table >
}   