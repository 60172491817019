import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import {
  ArrayParam,
  NumberParam,
  StringParam,
  withDefault,
  withQueryParams,
} from "use-query-params";
import { ActionMenu } from "../../../theme/ActionMenu";
import { Params } from "../../users/listing";
import AddPlaybook from "../create/create";
import styles from "./Playbooks.module.css";
import DeletePlaybook from "../delete/delete";
import { Api } from "../../../components/Axios";
import { useToasts } from '../../../components/core';
import { AxiosError } from "axios";
import { getRisk } from "../../../utils/risk-level";
import { useAuthDetails } from '../../../components/Authorization';
import { IdAssetCustomList } from "./IDAssetCustomList";
import { IIdAssetState } from "../ref/IdPopUp";
import { IPatternConfigItem } from "../ref/PatternForm/PatternFormGenerator";
import './Playbooks.scss';
import { convertToCommaValue } from "../../../utils/util-methods";
import * as Ref from '../ref';
import { PlaybookModal } from "../ref/AdvancedPlaybook/PlaybookModal";
import { PlaybookActivate, renderPlaybookSummaryColumn } from "../ref/AdvancedPlaybook/PlaybookGeneral";
import { ResetState } from "../constants/DefaultState";
import { IServerResponse } from "../../../types/response-types";
import { IPlaybook, IPlaybookRule } from "../ref/AdvancedPlaybook/PlaybookTypes";
import { AMToolTipAd } from "../../../components/core/AMTooltipAd/AMTooltipAd";
import ReactPaginate from "react-paginate";
import { getIssueNameById, getPlaybookList, updateCategories } from "../helpers/playbook-helper";
import { RuleType } from "../constants/Constants";
import { getFilterTemplateBySearchKey } from '../../../components/core/PostureFilter/posture-filter-utils'
import { PostureSearchTemplate } from "../../identity_asset/constants/PostureSearchTemplate";

interface IdAssetCustomListState {
  show: boolean,
  data: IIdAssetState,
  title: "Identities" | "Assets" | "Authorized Assets" | "Authorized Identities & Hosts" | "",
  patternFormConfig: Array<IPatternConfigItem>
}

const Playbooks = ({ query, setQuery }: any) => {
  const {
    q: q,
    page: page_number,
    rpp: record_per_page,
    order_by: order,
    sort_by: sort,
    type: type
  } = query;
  const { authDetails } = useAuthDetails()!;
  const [selectedColumn, setSelectedColumn] = useState(query.sort_by || 'playbook_name');
  const [currentSort, setCurrentSort] = useState(query.order_by == 'asc' ?
    ' tablesort_up tablesort_up_selected ' : ' tablesort_down tablesort_down_selected ');
  const [responseData, setResponseData] = useState<IServerResponse<IPlaybook>>();
  const [customListPopUp, setCustomListPopUp] = useState<IdAssetCustomListState>
    ({ data: { pattern: {}, type: [] }, show: false, title: "", patternFormConfig: [] });

  const { addToast } = useToasts();

  let history = useHistory();
  const queryRef = useRef<any>();
  const [showActivate, setShowActivate] = useState({ id: undefined, status: false, name: '', payload: undefined });
  const { loadDefaultPlaybookConfigViewByRuleType } = Ref.usePlaybookConfig();

  let param: Params = {
    q: q,
    page: page_number,
    rpp: record_per_page,
    order_by: order,
    sort_by: sort,
    type: type
  };

  const addPlaybookHandler = () => {
    history.replace(`/playbooks/new${history.location.search}`);
  };

  const LoadSorting = (field: string) => {
    if (param.sort_by === field) {
      param.order_by = param.order_by === "asc" ? "desc" : "asc";
      setQuery(param);
    } else {
      param.sort_by = field;
      param.order_by = "desc";
      setQuery(param);
    }
  };

  const getRiskStyle = (risk: string) => {
    return styles[risk.toLowerCase() as keyof typeof styles];
  }

  const renderSummaryColumn = (val: IPlaybookRule, item: IPlaybook, index: number) => {
    if ((responseData?.result || []).length - 1 == index) {
      updateScrolls();
    }
    const res = renderPlaybookSummaryColumn(val?.search_field_info?.search_field, val, true, item)

    return res;
  }

  const renderIdentities = (item: IPlaybook, index: number) => {
    const cnt = item.issue_type == RuleType.UNAUTHORIZED_ASSET_ACCESS ||
      item.issue_type == RuleType.UNAUTHORIZED_IDENTITY_ACCESS || item.issue_type == RuleType.LACK_OF_MFA ||
      item?.issue_type == RuleType.ACCOUNT_TAKEOVER
      ? 3 : 2;
    return (
      <div className='wildth100 rules-summary'>
        {index >= 0 && (
          <>
            <span>Rule 1 of {item?.issue_rule_list?.length || 0}</span>
            <ul style={{ marginLeft: '1.5em' }}>
              {
                ResetState.hasOwnProperty(item.issue_type) &&
                <li className="params-list-item"><strong>Parameters</strong> -&nbsp;
                  {loadDefaultPlaybookConfigViewByRuleType(
                    item.issue_type || '',
                    {
                      rules: { config: item.issue_rule_list[0] },
                      params: { isPlaybookListing: true }
                    }
                  )}
                </li>}
              {
                item.issue_rule_list.map((val: IPlaybookRule, ind: number) => {
                  if (val?.search_field_info && ind == 0) {
                    return renderSummaryColumn(val, item, index)
                  }
                  return <></>
                })
              }
            </ul>
          </>
        )}
        {(renderSummaryColumn(item.issue_rule_list[0], item, index).length > cnt)
          &&
          <div
            className='shadowbox more-button-summary'
            onClick={() => {
              history.replace(`/playbooks/view/${item?._id}${history.location.search}`, {
                ruleName: item?.issue_type,
                search: window.location.search
              });
            }}
          >
            ...
          </div>
        }
      </div>
    );
  }

  const renderPostureIssues = (item) => {
    if (item['issue_rule_list'][0]['search_field_info']['search_field'] !== null) {
      let res = [] as Array<Record<string, any>>;
      for (let i in item['issue_rule_list'][0]['search_field_info']['search_field']) {
        const filterTemplateObj = getFilterTemplateBySearchKey(PostureSearchTemplate, i);
        res.push({
          field: i,
          type: item['issue_rule_list'][0]['search_field_info']['search_field'][i]['type'],
          value: item['issue_rule_list'][0]['search_field_info']['search_field'][i]['value'],
          label: filterTemplateObj && filterTemplateObj['label'] ? filterTemplateObj['label'] : ''
        })
      }
      return (
        <div className="wildth100 rules-summary">
          <span>Rule 1 of 1</span>
          <ul style={{ marginLeft: "1.5em" }} >
            {res.map(item => (
              <li key={item['field']}><strong>{item['label']}</strong> {item['type']} {Array.isArray(item['value']) ? item['value'].join(',') : item['value']}</li>
            ))}
          </ul>
        </div>
      );
    }
    return null;
  }

  useEffect(() => {
    updateScrolls();
  }, [responseData])

  const updateScrolls = () => {
    setTimeout(() => {
      const summaryList = document.getElementsByClassName('rules-summary');
      const summaryListArray = Array.from(summaryList);
      summaryListArray.forEach((el: Element) => {
        if (el && el?.clientHeight < el?.scrollHeight) {
          const listItems = el.getElementsByTagName('li');
          listItems[4].remove();
        }
      });
    }, 1000)
  }

  const handleIncidentPageRedirect = (item: IPlaybook) => {
    const _from = new Date()
    const _fromTs = _from.setHours(_from.getHours() - 72)
    const _to = new Date();
    const _toTs = _to.setMinutes(_to.getMinutes())
    const momentFrom = moment.utc(new Date(_fromTs)).unix()
    const momentTo = moment.utc(new Date(_toTs)).unix();
    history.push(`/issues?q=status%3AOpen%2Bpb_name%3A${item.playbook_name}%2Blast_flow_time%7C${momentFrom}%2C${momentTo}`);
  }

  const generateRows = (data: Array<IPlaybook>) => {
    return data.map((item: IPlaybook, index: number) => {
      return (
        <tr key={item._id}>
          <td className="vert_mid_ho_left">
            <span className={styles.pb_name + (' width100 text-break ')}>
              {item?.playbook_name}
            </span>
          </td ><td className="vert_mid_ho_left">{getIssueNameById(item?.issue_type)}
          </td>
          <td className="vert_mid_ho_left">
            <div style={{ width: "18px", marginRight: '0px', marginLeft: 7 }}
              title={getRisk(item!.risk)}
              className={getRiskStyle(getRisk(item!.risk)!)}></div>
          </td>
          <td className="vert_mid_ho_left">
            {item?.is_posture_playbook ? renderPostureIssues(item) : renderIdentities(item, index)}
          </td>
          <td className="align_center vert_middle">
            {item.last_72_hour_active_incident_count > 0 && <span className="shadowbox width80"
              onClick={handleIncidentPageRedirect.bind(null, item)}
            >
              {convertToCommaValue(item.last_72_hour_active_incident_count)}
            </span>}
            {item.last_72_hour_active_incident_count === 0 && <span className="width80"
            >
              {convertToCommaValue(item.last_72_hour_active_incident_count)}
            </span>}
          </td>
          <td className="vert_mid_ho_left">{item?.last_modified_by}</td>
          <td className="vert_mid_ho_left">{moment(item.modified_date).format('MMM DD, YYYY')}<br />{moment(item.modified_date).format('h:mm A')}</td>
          <td className={"align_center vert_middle" + (!item?.is_active ? ' critical_red' : '')}>{item?.is_active ? 'Active' : 'Inactive'}</td>
          <td className={"align_center vert_middle"} style={{ width: '10px', textAlign: 'center' }}>
            <ActionMenu>
              <ul>
                {authDetails.permissions.Playbooks.playbooks !== "readonly" &&
                  <li>
                    <Link to={{
                      pathname: `/playbooks/create/${item?._id}`, search: window.location.search, state: {
                        ruleName: item?.issue_type
                      }
                    }} data-action="Copy" id={item?._id}
                    >
                      Copy
                    </Link>
                  </li>
                }
                {authDetails.permissions.Playbooks.playbooks !== "readonly" && item.is_active ?
                  <li>
                    <Link to={{
                      pathname: `/playbooks/edit/${item?._id}`, search: window.location.search, state: {
                        ruleName: item?.issue_type
                      }
                    }} data-action="EditAction">
                      Edit
                    </Link>
                  </li> : null
                }
                <li>
                  <Link to={{
                    pathname: `/playbooks/view/${item?._id}`, search: window.location.search, state: {
                      ruleName: item?.issue_type
                    }
                  }} data-action="Edit" >
                    View
                  </Link>
                </li>
                {authDetails.permissions.Playbooks.playbooks !== "readonly"
                  && item.is_active
                  ?
                  <li>
                    <Link to='#' data-action="deactivate"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowActivate({ id: item._id, status: false, name: item.playbook_name, payload: item })
                      }}
                    >
                      Deactivate
                    </Link>
                  </li>
                  : null
                }
                {authDetails.permissions.Playbooks.playbooks !== "readonly"
                  && !item.is_active
                  ?
                  <li>
                    <Link to='#' data-action="activate"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowActivate({ id: item._id, status: true, name: item.playbook_name, payload: item })
                      }}
                    >
                      Activate
                    </Link>
                  </li>
                  : null
                }
                {authDetails.permissions.Playbooks.playbooks !== "readonly" ?
                  <li>
                    <Link to={{ pathname: `/playbooks/delete/${item?._id}/${item.playbook_name}`, search: window.location.search }} data-action="Delete">
                      Delete
                    </Link>
                  </li>
                  : null
                }
              </ul>
            </ActionMenu>
          </td>
        </tr >
      );
    });
  };


  const handlePaginate = (selectedPage: any) => {
    param.page = selectedPage.selected === 0 ? 1 : selectedPage.selected + 1
    setQuery(param)
  }

  const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    param.q = e.currentTarget.value;
    param.page = 1;
    if (e.key === "Enter") {
      setQuery(param);
    }
  }

  const handleOnClickSearch = (e: React.MouseEvent<HTMLInputElement>) => {
    param.q = queryRef?.current?.value || '';
    param.page = 1;
    setQuery(param);
  }

  const onHeaderClick = (columnName: string) => {
    LoadSorting(columnName)
    setSelectedColumn(columnName);
  }

  const highlightSortDir = () => {
    if (query.order_by === "asc") {
      setCurrentSort(" tablesort_up tablesort_up_selected ")
    } else if (query.order_by === "desc") {
      setCurrentSort(" tablesort_down tablesort_down_selected ")
    }
  }

  useEffect(() => {
    fetchPlaybooks();
    highlightSortDir();

  }, [query])

  const updateCategoriesForListing = (data: IServerResponse<IPlaybook>) => {
    data.result.forEach((pl: IPlaybook) => {
      updateCategories(pl)
    });
  }

  const fetchPlaybooks = () => {
    Api.get("/playbooks", {
      params: { ...param, rpp: 100 }
    })
      .then(res => {
        if (res.status === 200) {
          updateCategoriesForListing(res.data);
          setResponseData(res.data);
        }
      })
      .catch((err: AxiosError) => {
        if (err.response?.status === 401) {
          window.location.href = '/login'
        }
        else {
          addToast("Sorry, something went wrong there, try again.", {
            appearance: 'error',
            autoDismiss: true,
          })
        }
      });
  }

  return (
    <>
      <Switch>
        <Route path={`/playbooks/new`} render={() => <AddPlaybook updatePlaybooksData={() => {
          history.replace(`/playbooks${history.location.search}`);
          fetchPlaybooks();
        }} totalPlaybooks={responseData?.total} />} />
        <Route path={`/playbooks/create/:id`} render={() => <PlaybookModal
          isOpen={true}
          onCloseHandler={() => {
            history.replace(`/playbooks${history.location.search}`);
            fetchPlaybooks();
          }}
          title="New Playbook"
          formType='create'
          query={query}
        />} />
        <Route path={`/playbooks/edit/:id`} render={() => <PlaybookModal
          isOpen={true}
          onCloseHandler={() => {
            history.replace(`/playbooks${history.location.search}`);
            fetchPlaybooks();
          }}
          title="Edit Playbook"
          formType='edit' />} />
        <Route path={`/playbooks/view/:id`} render={() => <PlaybookModal
          isOpen={true}
          onCloseHandler={() => {
            history.replace(`/playbooks${history.location.search}`);
            fetchPlaybooks();
          }}
          title="View Playbook"
          formType='view' />} />
        <Route path={`/playbooks/delete/:id/:pb_name`} render={() => <DeletePlaybook updatePlaybooksData={fetchPlaybooks} />} />
      </Switch>
      <div className={styles.page_title_area + ' playbook-list-search-container'}>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className='font18'>Playbooks</div>
          <div className="search_container issue_page scrollbar-container marginLeftAuto">
            <div className="search_white_icon flex_none" style={{ cursor: 'pointer' }}
              onClick={handleOnClickSearch}
            >&nbsp;</div>&nbsp;
            <input
              className="search_input flex_none admin-page"
              onKeyUp={handleSearch}
              placeholder="Search.."
              name='query'
              ref={queryRef}
            />
          </div>
          {authDetails.permissions.Playbooks.playbooks !== "readonly" ?
            <div className="launch_button marginleft10">
              <button type="button" className="button_main" id="new_playbook" onClick={addPlaybookHandler}>Add</button>
            </div>
            : <div className="launch_button">
              <div className="button_main" style={{ background: 'transparent' }}></div>
            </div>
          }
        </div>

      </div>
      <div className="playbook-container playbook_list_root_container">
        <div id="tab1" className={styles.table_container}>
          <table id="table" className={styles.playbook_table + ' sticky_table_top'}>
            <thead>
              <tr>
                <th
                  style={{ width: "18%" }}
                  className={"align_left " + (
                    (selectedColumn == "playbook_name" ? currentSort : " tablesort_down"))}
                  onClick={() => { onHeaderClick("playbook_name") }}
                >
                  Playbook Name
                </th>
                <th
                  style={{ width: "18%" }}
                  className={"align_left " + (
                    (selectedColumn == "issue_type" ? currentSort : " tablesort_down"))}
                  onClick={() => { onHeaderClick("issue_type") }}
                >
                  Issue
                </th>
                <th
                  style={{ width: "10px", margin: 0, padding: 0, paddingRight: '7px' }}
                  className={" " + (
                    (selectedColumn == "risk" ? currentSort : " tablesort_down"))}
                  onClick={() => { onHeaderClick("risk") }}
                >
                  Risk
                </th>
                <th className="align_left " style={{ width: "60%" }}>
                  Playbook Summary
                </th>
                <th className={" " + (
                  (selectedColumn == "last_72_hour_active_incident_count" ? currentSort : " tablesort_down"))} style={{ width: "16%" }}
                  onClick={() => { onHeaderClick("last_72_hour_active_incident_count") }}
                >
                  <AMToolTipAd
                    title={`Incidents updated with new activity in past 72 hours`}
                  ><span>Incidents Updated (72 hours)</span>
                  </AMToolTipAd>
                </th>
                <th
                  style={{ width: "16%" }}
                  className={"align_left " + (
                    (selectedColumn == "last_modified_by" ? currentSort : " tablesort_down"))}
                  onClick={() => { onHeaderClick("last_modified_by") }}
                >
                  Last Modified By
                </th>
                <th
                  style={{ width: "10%" }}
                  className={"align_left" + (
                    (selectedColumn == "modified_date" ? currentSort : " tablesort_down"))}
                  onClick={() => { onHeaderClick("modified_date") }}
                >
                  Last Modified At
                </th>
                <th
                  style={{ minWidth: "60px", padding: 0, paddingRight: 5 }}
                  className={(
                    (selectedColumn == "is_active" ? currentSort : " tablesort_down"))}
                  onClick={() => { onHeaderClick("is_active") }}
                >
                  Status
                </th>
                {responseData ? <th style={{ minWidth: "30px" }}>Actions</th> : null}
              </tr>
            </thead>
            <tbody>
              {
                responseData && responseData.result.length > 0 ? generateRows(responseData.result) :
                  <tr>
                    <td colSpan={9} style={{ textAlign: 'center' }}>No record found.</td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
      </div>

      {responseData && responseData?.total > 100 && <ReactPaginate
        previousLabel={"← Previous"}
        nextLabel={"Next →"}
        pageCount={responseData && responseData.result.length > 0 ? (Math.ceil(responseData.total / responseData.rpp)) : 1}
        pageRangeDisplayed={2}
        marginPagesDisplayed={2}
        containerClassName={"pagination"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"pagination__link--disabled"}
        activeClassName={"pagination__link--active"}
        onPageChange={handlePaginate}
      />}

      {
        customListPopUp?.show && <IdAssetCustomList data={customListPopUp?.data}
          handleCloseSource={() => setCustomListPopUp({ show: false, data: { pattern: {}, type: [] }, title: "", patternFormConfig: [] })}
          popUpTitle={customListPopUp.title}
          patternFormConfig={customListPopUp.patternFormConfig}
        />
      }
      {showActivate?.id && <PlaybookActivate id={showActivate?.id} name={showActivate.name} status={showActivate.status}
        payload={showActivate.payload}
        onPlaybookActivateClose={(status: boolean) => {
          setShowActivate({ ...showActivate, id: undefined, });
          if (status) {
            fetchPlaybooks();
          }
        }}
      />}
    </>
  );
};

export default withQueryParams(
  {
    q: StringParam,
    page: StringParam,
    rpp: NumberParam,
    sort_by: StringParam,
    order_by: StringParam,
    filters: withDefault(ArrayParam, []),
    type: StringParam
  },
  Playbooks
);
